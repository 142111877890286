import React from 'react';

import { uuid } from 'uuidv4';
import {
  SearchComponent,
  ISearchComponent,
} from '../../components/SearchComponent';
import createModule from '../../components/SearchComponent/modules/Create';
import updateModule from '../../components/SearchComponent/modules/Update';
import updateUniqueModule from '../../components/SearchComponent/modules/UpdateUnique';
import createCertificateModule from './modules/CreateCertificate';

import deleteModule from '../../components/SearchComponent/modules/Delete';
import { Container, Header, Body } from './styles';
import api from '../../services/api';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/certificates';

  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    title: { ref: 'title', column: 'title', label: 'Título' },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    title: {
      model: 'input',
      type: 'text',
      name: 'title',
      label: 'Título',
    },
  };

  const formSchemaUpdate = {
    title: {
      model: 'input',
      type: 'text',
      name: 'title',
      label: 'Título',
    },

    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Imagem',
    },
    content: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'content',
      label: 'content Icons',
      list: [
        { label: 'Texto', name: 'text' },
        { label: 'Tamanho', name: 'width' },
        { label: 'Tamanho da fonte', name: 'size' },
        { label: 'Topo', name: 'top' },
        { label: 'Esquerda', name: 'left' },
        { label: 'Alinhamento', name: 'align' },
        { label: 'Negrito', name: 'fontWeight' },
        { label: 'Elemento HTML', name: 'element' },
      ],
    },
    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Tipo',

      options: [
        { label: 'Global', value: 1},
        { label: 'Palestra', value: 2}

      ]
    
    },
    room_id: {
      alias: 'room_id',
      model: 'selectApi',
      name: 'room_id',
      label: 'Palestra',
      endpoint,
    },
    days: {
      model: 'input',
      type: 'text',
      name: 'days',
      label: 'Data',
    },
    hours: {
      model: 'input',
      type: 'text',
      name: 'hours',
      label: 'Horas',
    },
  };

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };

  const key = uuid();

  const config: ISearchComponent = {
    idKey: key,
    title: 'Certificado',
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        createModule({
          title: 'Certificado',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
      createCertificateModule({
        lineData,
        title: 'Gerar Certificado',
        formSchema: formSchemaUpdate,
        validationSchema: {},
        endpoint,
      }),
      (lineData: Record<string, any>) =>
        updateUniqueModule({
          lineData,
          title: 'Certificado',
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        deleteModule({
          lineData,
          title: 'Certificado',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
