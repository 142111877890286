import React from 'react';
import { FaChalkboardTeacher } from 'react-icons/fa';
import CopyToClipboard from '../../../components/CopyToClipboard';
import { urlWebsite } from '../../../config';

interface ICreateModule {
    lineData: Record<string, any>;
  }
  
  export default function SpeakerLinkModule({
    lineData,
  }: ICreateModule): JSX.Element {



  const moduleCreate = (): JSX.Element => { 
console.log(lineData);
    return lineData.hash ? <CopyToClipboard title="Copiar link de Moderador" Icon={FaChalkboardTeacher} text={`${urlWebsite}/moderator/access/${lineData.hash}`}/> : <></>;
  }
     
  

  return moduleCreate();
}
