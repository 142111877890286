import styled, { keyframes } from 'styled-components';
import { animated } from 'react-spring';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

export const Container = styled(animated.div)`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-top: 100px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px #0002;
  display: flex;
  justify-content: center;
  align-items: center;

  .defaultButton {
     {
      background: #888;
      color: white;
      width: 100%;
      padding: 20px;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      border: 0;
      border-radius: 8px;

      &:hover {
        opacity: 0.9;
      }

      > svg {
        margin-right: 15px;
      }
    }
  }
`;

export const Body = styled(animated.div)`
  background: rgba(0, 0, 0, 0.9);
  max-width: 90%;
  justify-content: center;
  padding: 40px 30px;
  animation: ${fadeFromLeft} 1s;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 750px) {
    max-width: 100%;
    padding: 20px 5px;
  }
`;

export const Content = styled(animated.div)`
  width: 100%;
  max-height: 70vh;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Footer = styled(animated.div)`
  width: 100%;
  min-height: 44px;
  padding: 10px;
  text-align: center;

  > button {
    background: #888;
    color: white;
    width: 200px;
    padding: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 8px;
    margin: auto;

    &:hover {
      opacity: 0.9;
    }

    > svg {
      margin-right: 15px;
    }
  }
`;

export const Header = styled(animated.div)`
  width: 100%;

  padding: 10px;
`;
