import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { useAuth } from '../../hooks/Auth';
import { VideoProvider } from '../../hooks/Video';
import ClassRoomContainer from '../../components/ClassRoomContainer';
import { apiUrl } from '../../config';
import { LoaderWithElement } from '../../components/Loader';
import { useSocket } from '../../hooks/Socket';
import Button from '../../components/Button';

import { useToast } from '../../hooks/Toast';

const ClassRoom: React.FC = () => {
  const { token } = useAuth();
  const { socket } = useSocket();
  const { addToast } = useToast();
  const [online, setOnline] = useState(0);
  const [removeUser, setRemoveUser] = useState(false);

  useEffect(() => {
    socket.on('removeUser', (state: Record<string, any>) => {
      setRemoveUser(true);
    });

    socket.on('ListLastPrivateChatMessage', state => {
      addToast({
        title: state.message_from_name,
        description: state.message,
        type: 'info',
      });
    });
  }, [socket]);

  useEffect(() => {
    socket.emit('subscribePlatform');
  }, []);
  return removeUser ? (
    <LoaderWithElement
      message={(
        <>
          <p>Você foi desconectado</p>
          <Button type="button" onClick={() => window.location.reload()}>
            Entrar novamente
          </Button>
        </>
      )}
    />
  ) : (
    <VideoProvider>
      <ClassRoomContainer />
    </VideoProvider>
  );
};

export default ClassRoom;
