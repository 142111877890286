import { format, parseISO, addHours, getHours } from 'date-fns';
import pt from 'date-fns/locale/pt-BR/index.js';

export const date = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);

    formattedDate = format(parsedDate, 'dd/MM/yyyy', { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const dateSimple = (dateValue: string): string => {
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);

    formattedDate = format(parsedDate, 'dd/MM', { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const hour = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);

    formattedDate = format(parsedDate, "HH'h'mm", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const dateLanguage = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);

    formattedDate = format(parsedDate, "dd 'de' MMMM '<br/>' 	EEEE'-feira'", {
      locale: pt,
    });
  } catch (err) {
    return '';
  }
  return formattedDate;
};
