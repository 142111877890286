export const hash = '6940b3f2-128c-4eb3-bc1f-8ee34d755048';

// export const gateKey = '';
export const gateKey = '';
export const recaptchaKey = '6LdKIrgZAAAAAFCgNLBhpQNO_a29D30zbLGQx9mb';
export const apiUrl = 'https://api-enfapconfies.encontrodigital.com.br';
export const apiSocket = 'https://socketv3.encontrodigital.com.br/';
// export const apiSocket = 'http://localhost:8001';
//export const apiUrl = 'http://localhost:8000';
export const urlWebsite = 'https://enfapconfies.encontrodigital.com.br';

export const home = 'Home';
// export const urlWebsite = 'http://localhost:3000';
export const eventName = '1º ENFAP/CONFIES';
export const logo = '/assets/evento/logo.png';
export const background = '#0099A9';
export const authTitle = 'enfapconfies';
export const social = {
  whatsapp: '',
  instagram: '',
  facebook: '',
};

export const supportNumber = `5551981476007`;
export const dadosOrganizacao = `ENFAP/CONFIES`;
export const defaultTheme = {
  defaultBackground: 'rgb(200,200,200)',
  defaultColor: '#007433',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: 'none',
    color: '#007433',
    shadow: '0',
    logoColor: '#007433',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};
