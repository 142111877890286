import React from 'react';
import { useAuth } from '../../hooks/Auth';
import { useLanguage } from '../../hooks/Language';
import { useToast } from '../../hooks/Toast';
import { Button } from '../Button/styles';
import Whatsapp from '../Whatsapp';
import CopyToClipboard from '../CopyToClipboard';
import { eventName, supportNumber } from '../../config';

// import { Container } from './styles';
interface VimeoProps {
  id: string;
  keyName?: string;
  time?: string;
  roomName?: string;
  class_name ?: string;
}

const ZoomContainer: React.FC<VimeoProps> = ({ id = '', roomName = '', keyName, time = '0', class_name = '' }) => {

  const { user} = useAuth();
  const {translate} = useLanguage();
  const {addToast} = useToast();




  return (
    <div style={{padding: '10px', textAlign: 'center', marginTop: '30px'}}>
      <h2>{translate('Instruções')}</h2><br/>
      <p style={{marginBottom: '20px'}}><div style={{marginBottom: '20px'}}>
    
1. {translate('Clique no botão "Acessar sala"')}<br/>
2. {translate('Caso enfrente algum problema, clique no botão de suporte técnico')}


      
        <br/></div>

      </p>
      <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
     <a style={{marginBottom: '0 auto', display: 'flex'}} href={`${id}`} target="_BLANK"><button className="defaultButtonReverse" type="button">{translate('Acessar sala')}</button></a>
     <Whatsapp number={supportNumber} text={`Estou tentando acessar a sala de Zoom ${roomName} - ${eventName}`} message="Suporte Técnico"/>
     </div></div>
  );
};

export default ZoomContainer;
