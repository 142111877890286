import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../Input';
import Button from '../Button';
import Loader from '../Loader';
import MaskedInput from '../MaskedInput';
import { ResponseMessage } from '../ResponseMessage';
import getValidationErrors from '../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../SelectSimple';
import Whatsapp from '../WhatsAppCompartilhar';

import api from '../../services/api';
import { authTitle, recaptchaKey } from '../../config';
import { countries } from './countries';
import TextareaInput from '../TextareaInput';
import { useToast } from '../../hooks/Toast';
import CheckboxInput from '../CheckboxInput';
import getCep from '../../services/cep';

interface IRecoverFormData {
  [key : string] : any;
}


interface IRead {
  [key: string]: any;
}


interface ICaptcha {
  getValue(): string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
const siglas = readSigla();
  const {addToast} = useToast();

  const [countryISO, setCountryISO] = useState('BR');

  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),

        phone: Yup.string()
          .required('Celular obrigatório'),
        password: Yup.string().required('Senha obrigatória'),
        especialidade: Yup.string().required('O campo Sigla é obrigatório'),

        education_id: Yup.string().required(
          'Prencha a categoria da sua Fundação',
        ),
        country: Yup.string().required('País da Fundação obrigatório'),
        state: Yup.string().required('Estado da Fundação obrigatório'),
        city: Yup.string().required('Cidade da Fundação obrigatório'),
        extra_3: Yup.string()
          .required('Informe se sua entidade é afiliado ao CONFIES'),
 
        term: Yup.string().required('Informe se você autoriza o compartilhamento de seus dados.'),
      });
      data.phone = data.phone.toString().replace(/[^0-9]/g, '');



      data.company_document_number.toString().replace(/[^0-9]/g, '');
      await schema.validate(data, { abortEarly: false });

      const {
        name,
        email,
        password,
        company,
        phone,
        position,
        know_about,
        birth_date,
        company_document_number,
        extra_1,
        extra_2,
        extra_3,
        extra_4,
        education_id,
        especialidade,
        term,
        country,
          city,
          state,
      } = data;

      const captchaValue =
      getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
      false;

      const newUser = await api
        .post('/signup', {
          name,
          email,
          password,
          company,
          phone,
          know_about,
          position,

          company_document_number,
          extra_1,
          extra_2,
          extra_3,
          extra_4,
          education_id,
          especialidade,

          birth_date,
          captcha : captchaValue,
          country,
          city,
          state,
          term
        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error.response.data.message,
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      setSuccess(true);
      setLoading(false);
     

      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{color: '#333'}}>
                <h2 className="titleH2" style={{color: '#333'}}> Cadastro realizado com sucesso!</h2>
                <br />
                <Link to="login">
                  <button className="defaultButton" type="button">
                    Acessar
                  </button>
                </Link>
                <br />
                Aproveite e indique para seus amigos!
                <Whatsapp
                  text="Olá! Acabei de me inscrever no  Encontro Norte das Fundações de Apoio - ENFAP/CONFIES. Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link: https://enfapconfies.com.br/"
                  number=""
                  message="Compartilhe!"
                />
              </p>
            }
          />
        ) : (<>
           
                  <Form ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR'}}>
                    <h1 style={{color: '#333'}}>Novo cadastro</h1>

                    <Input name="name" placeholder="Nome completo" />
                    <Input name="email" placeholder="E-mail" />

                    <MaskedInput
                      mask="(99) 99999.9999"
                      name="phone"
                      placeholder="Celular"
                    />

                


                    <SelectSimple
                      options={siglas}
                      name="especialidade"
                      formRef={formRef}
                      label="Sigla da Fundação"
                      onChange={a => {
                        const value: IRead = { ...a };

                        if (value && formRef && formRef.current) {
                          formRef.current?.setFieldValue(
                            'company',
                            value.razao_social,
                          );

                          formRef.current?.setFieldValue(
                            'education_id',
                            value.classificacao,
                          );
                        }
                      }}
                    />

                    <Input
                      name="company"
                      placeholder="Nome da Fundação a qual pertence:"
                    />
                     <Input name="position" placeholder="Cargo na Fundação" />

                    <MaskedInput
                      mask="99.999.999/9999-99"
                      name="company_document_number"
                      placeholder="CNPJ da Fundação a qual pertence:"
                    />

                    <SelectSimple
                      options={[
                        { value: 'A', label: 'A' },
                        { value: 'B', label: 'B' },
                        { value: 'C', label: 'C' },
                      ]}
                      name="education_id"
                      formRef={formRef}
                      label="Classificação da Fundação a qual pertence:"
                    />

                    <SelectSimple
                      options={[
                        { value: 'Norte', label: 'Norte' },
                        { value: 'Nordeste', label: 'Nordeste' },
                        { value: 'Centro-Oeste', label: 'Centro-Oeste' },
                        { value: 'Sudeste', label: 'Sudeste' },
                        { value: 'Sul', label: 'Sul' },
                      ]}
                      name="extra_1"
                      formRef={formRef}
                      label="Região da Fundação a qual pertence"
                    />

<SelectSimple
                      options={[
                        { value: 'Sim', label: 'Sim' },
                        { value: 'Não', label: 'Não' },
                      ]}
                      name="extra_3"
                      formRef={formRef}
                      label="Afiliada ao CONFIES?"
                    />

<SelectSimple setCallback={(value) => setCountryISO(value) } options={countries} name="country" label={'Pais da Fundação'}/>
                    
{ countryISO === 'BR' ?     <SelectSimple 
                      options={[
                        { label: 'Acre (AC)', value: 'Acre (AC)' },
                        { label: 'Alagoas (AL)', value: 'Alagoas (AL)' },
                        { label: 'Amapá (AP)', value: 'Amapá (AP)' },
                        { label: 'Amazonas (AM)', value: 'Amazonas (AM)' },
                        { label: 'Bahia (BA)', value: 'Bahia (BA)' },
                        { label: 'Ceará (CE)', value: 'Ceará (CE)' },
                        {
                          label: 'Distrito Federal (DF)',
                          value: 'Distrito Federal (DF)',
                        },
                        { label: 'Espírito Santo (ES)', value: 'Espírito Santo (ES)' },
                        { label: 'Goiás (GO)', value: 'Goiás (GO)' },
                        { label: 'Maranhão (MA)', value: 'Maranhão (MA)' },
                        { label: 'Mato Grosso (MT)', value: 'Mato Grosso (MT)' },
                        {
                          label: 'Mato Grosso do Sul (MS)',
                          value: 'Mato Grosso do Sul (MS)',
                        },
                        { label: 'Minas Gerais (MG)', value: 'Minas Gerais (MG)' },
                        { label: 'Pará (PA)', value: 'Pará (PA)' },
                        { label: 'Paraíba (PB)', value: 'Paraíba (PB)' },
                        { label: 'Paraná (PR)', value: 'Paraná (PR)' },
                        { label: 'Pernambuco (PE)', value: 'Pernambuco (PE)' },
                        { label: 'Piauí (PI)', value: 'Piauí (PI)' },
                        { label: 'Rio de Janeiro (RJ)', value: 'Rio de Janeiro (RJ)' },
                        {
                          label: 'Rio Grande do Norte (RN)',
                          value: 'Rio Grande do Norte (RN)',
                        },
                        {
                          label: 'Rio Grande do Sul (RS)',
                          value: 'Rio Grande do Sul (RS)',
                        },
                        { label: 'Rondônia (RO)', value: 'Rondônia (RO)' },
                        { label: 'Roraima (RR)', value: 'Roraima (RR)' },
                        { label: 'Santa Catarina (SC)', value: 'Santa Catarina (SC)' },
                        { label: 'São Paulo (SP)', value: 'São Paulo (SP)' },
                        { label: 'Sergipe (SE)', value: 'Sergipe (SE)' },
                        { label: 'Tocantins (TO)', value: 'Tocantins (TO)' },
                        { label: 'Não sou Brasileiro', value: 'Não sou Brasileiro' },
                      ]}
                      name="state"
                      formRef={formRef}
                      label={'Estado da Fundação'}
                    /> : 

<Input  name="state" placeholder={'Província / Região da Fundação'} />}  

<Input  name="city" placeholder={'Cidade da Fundação'} />

                
                   

                    <Input
                      icon={FiLock}
                      name="password"
                      type="password"
                      placeholder="senha"
                    />

                    <TextareaInput
                      name="know_about"
                      placeholder="Como ficou sabendo do evento?"
                    />


<CheckboxInput
              style={{ display: 'flex'  }}
              name="term"
              unique={true}
              preText={(
                <>
                  {' '}
                  Estou ciente de que estou fornecendo à {authTitle} e seus parceiros meus
                  contatos gerais, os quais são dados pessoais protegidos pela
                  Lei Geral de Proteção de Dados brasileira, para gestão de
                  relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Sim, eu autorizo o compartilhamento dos meus dados`,
                  label: 'Sim, eu autorizo o compartilhamento dos meus dados',
                  value: `Sim, eu autorizo o compartilhamento dos meus dados`,
                },
                {
                  id: `Não autorizo o compartilhamento dos meus dados`,
                  label: 'Não autorizo o compartilhamento dos meus dados',
                  value: `Não autorizo o compartilhamento dos meus dados`,
                },
              ]}
            />

<p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {'Ao me cadastrar, afirmo que li a'}
{' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {'Política de Privacidade'}
{' '}
                  </Link>
{' '}
                </strong>
                {'desta plataforma.'}
              </p>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

                    </div>
                    <button className="defaultButton" type="submit">
                      Finalizar inscrição

                  
                    </button>
                  </Form>
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;
