import React, { useState } from 'react';

import { uuid } from 'uuidv4';


import { createSchemaByForm, IYup } from '../../../../utils/SchemaCreator';

import api from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';
import { AxiosInstance } from 'axios';
import { FaCertificate } from 'react-icons/fa';

interface ICreateModule {
  apiRef ?: AxiosInstance,
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  buttonContent?: JSX.Element;
  addCallback?: Function;
  lineData : Record<string,any>
}

export default function CreateModule({
  apiRef = api,
  lineData,
  title,
  formSchema,
  validationSchema,
  endpoint,
  buttonContent = <span>Novo</span>,
  addCallback = () => {},
}: ICreateModule): JSX.Element {

  const { addToast } = useToast();
  const key = uuid();

  const handleCreate = async (
 
  ): Promise<void> => {

    if(!lineData.id){
      return addToast({ type: 'error', title: 'Erro ao identificar certificado' });
    }

    try {


      const response = await apiRef.post(`/certificates-user-create/${lineData!.id}`);

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }

   

      addToast({ type: 'success', title: response?.data?.message || 'Mensagem não identificada' });
  
    } catch (err) {
   
      return addToast({ type: 'error', title: err.response.data.error });
    }
  };



  const moduleCreate = (): JSX.Element => (
    <button type="button" onClick={handleCreate}>
      <FaCertificate/>
    </button>
  );

  return moduleCreate();
}
