import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../services/api';
import { date, hour, dateLanguage } from '../../utils/date';
import { Container, Sidebar, Body } from './styles';
import {addHours} from 'date-fns';
import { useToast } from '../../hooks/Toast';
import SelectApi from '../SelectApi';
import { Form } from '@unform/web';
import DatePicker from '../DatePicker';
import { Button } from '../Button/styles';
import SelectSimple from '../SelectSimple';
import ChartBar from '../Charts/Bar';

interface IOptions {
  text?: string;
  label?: string;
  value: string;
}

interface IChartData {
  hora : string;
  usuarios : number;
}

const Reporter: React.FC = () => {

  const [lessons,setLessons] = useState<Array<IOptions>>([]);
  const [graphData, setGraphData] = useState<Array<IChartData>>([]);
 
  const [status,setStatus] = useState({
    room_id : 0,
    date : new Date().toISOString(),
    period: '30',
    responsive : 'Não'
  });

  const callReport = async (data) => {
   
    setStatus(state => ({...state , ...data}));
    const response = await api.post('/reporter',{...data});

    if(response){
      setGraphData(response.data);
    }


  }

  const changePeriod = (value) => {
    setStatus(state => ({...state , period : value}));
  }


  const loadLessons = async () => {

    const response = await api.get('/lessons?limitItems=100');
    if(response){

      const data : Array<IOptions> = [];
        response.data.rows.map( m => {
          data.push({label : m.title, text : m.title, value : m.id});
        })

        setLessons(data);

    }


  }

useEffect( () => { 
  
  loadLessons();

  callReport({date : new Date().toISOString()})

},[])



  return (
    <Container>
      
 <Sidebar>
 <h2>Relatórios</h2>
   <Form onSubmit={callReport} initialData={status}>
   <div style={{display: 'flex', alignItems: 'stretch', justifyContent: 'center', flexWrap: 'wrap'}}>
   <div style={{width: '100%', maxWidth: '300px'}}><label>Período</label><SelectSimple setCallback={(value) => value !== status.period ? changePeriod(value) : false} label="" options={[{ label : "Diário", value : 'day'},{ label : "60 minutos", value : '60'}, { label : "30 minutos", value : '30'}, { label : "15 minutos", value : '15'}, { label : "10 minutos", value : '10'} ]}  name="period" /></div>
   <div style={{width: '100%', maxWidth: '300px'}}><label>Sala</label><SelectSimple label="" options={lessons}  name="room_id" /></div>
   <div style={{width: '100%', maxWidth: '300px', display: status.period === 'day' ? 'none' : 'flex', flexDirection: 'column'}}><label>Data</label><DatePicker name="date"/></div>
   <div style={{width: '100%', maxWidth: '300px'}}><label>Responsivo?</label><SelectSimple label="" options={[{ label : "Sim", value : 'Sim'}, { label : "Não", value : 'Não'} ]}  name="responsive" /></div>
  <div style={{width: '100%', maxWidth: '300px'}}> <Button>
     Filtrar
   </Button></div>
  
   
   </div>
   </Form>
 </Sidebar>
 
 <Body size={graphData.length} responsive={status.responsive}>
   
  {graphData && <div style={{width: '100%', height: '50vh'}}><ChartBar indexBy="hora" headers={['usuarios']} data={graphData} /></div>}

   </Body> 
    </Container>
  );
};
export default Reporter;
