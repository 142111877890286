import React from 'react';

import { uuid } from 'uuidv4';
import {
  SearchComponent,
  ISearchComponent,
} from '../../components/SearchComponent';
import createModule from '../../components/SearchComponent/modules/Create';
import updateModule from '../../components/SearchComponent/modules/UpdateUnique';
import deleteModule from '../../components/SearchComponent/modules/Delete';
import { Container, Header, Body } from './styles';
import api from '../../services/api';
import { useSocket } from '../../hooks/Socket';
import { Button } from '../../components/Button/styles';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

export const UpdateCoursesModule: React.FC = () => {

  const {socket} = useSocket();
  const updateCourses = () => {
    socket.emit('SendUpdateCourses');
  }

  return <Button style={{margin:'5px auto'}} onClick={() => updateCourses()}>Atualizar Cursos para participantes</Button>
}

const Course: React.FC = () => {
  const endpoint = '/Courses';

  const formSchema = {
    title: {
      model: 'input',
      name: 'title',
      label: 'Título',
      defaultValue: '',
    },
    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Tipo',
      
      options: [
        { label: 'Live', value: 'live'},
        { label: 'On Demand', value: 'ondemand'},


      ]
    
    },
    size: {
      model: 'selectSimple',
      name: 'size',
      label: 'Orientação',
      
      options: [
        { label: 'Quadrado', value: 'square'},
        { label: '16:9', value: '16:9'},


      ]
    
    },
    paid: {
      model: 'selectSimple',
      name: 'paid',
      label: 'Pago?',
      
      options: [
        { label: 'Pago', value: 'paid'},
        { label: 'Gratuito', value: 'free'},


      ]
    
    },
    lessons: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong>
            (
{data.id})
{' '}
{data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
{data.id})
{data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </>
      ),
      storageType: 'json',
      endpoint: `/lessons`,
      name: 'lessons',
      label: 'Aulas',
    },
  };


  const formSchemaUpdate = {
    title: {
      model: 'input',
      name: 'title',
      label: 'Título',
      defaultValue: '',
    },
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Tipo',
      
      options: [
        { label: 'Live', value: 'live'},
        { label: 'On Demand', value: 'ondemand'},


      ]
    
    },
    size: {
      model: 'selectSimple',
      name: 'size',
      label: 'Orientação',
      
      options: [
        { label: 'Quadrado', value: 'square'},
        { label: '16:9', value: '16:9'},


      ]
    
    },
    paid: {
      model: 'selectSimple',
      name: 'paid',
      label: 'Pago?',
      
      options: [
        { label: 'Pago', value: 'paid'},
        { label: 'Gratuito', value: 'free'},


      ]
    
    },
    lessons: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong>
            (
{data.id})
{' '}
{data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
{data.id})
{data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </>
      ),
      storageType: 'json',
      endpoint: `/lessons`,
      name: 'lessons',
      label: 'Aulas',
    },
  };
  const formValidation: Record<string, ValidationSchema> = {
  
  };

  const key2 = uuid();

  const schema2: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    image: { ref: 'image', column: 'image', label: 'Capa do Curso' },
    lessons: { ref: 'lessons', column: 'lessons', label: 'Aulas', show: false },
    size: { ref: 'size', column: 'size', label: 'Orientação' },
    type: { ref: 'type', column: 'type', label: 'Tipo' },
    paid: { ref: 'paid', column: 'paid', label: 'Pago?' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const config2: ISearchComponent = {
    idKey: key2,
    title: 'Cursos',
    endpoint: '/courses',
    schema: schema2,
    autoStart: true,
    headModules: [
      () =>
        createModule({
          title: 'Cursos',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        updateModule({
          lineData,
          title: 'Cursos',
          formSchema : formSchemaUpdate,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        deleteModule({
          lineData,
          title: 'Cursos',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };



  return (
    <Container>
      <UpdateCoursesModule/>
      <SearchComponent {...config2} />
    </Container>
  );
};

export default Course;
