import React, { useEffect } from 'react';
import { Wrapper, Background } from './styles';
import {Footer} from '../default/styles';
import { Pattern } from '../../../styles/ThemeComponents';
import Header from '../../../components/Header';
import { Menu } from '../../../components/Menu';
import { SocketProvider } from '../../../hooks/Socket';
import { useAuth } from '../../../hooks/Auth';
import Particles from 'react-particles-js';
const menuList = [
  {
    title: 'Home',
    url: '/',
    icon: '',
    external: true,
    fluid: false,
    targetID: '',
  },
  {
    title: 'Palestras',
    url: '/dashboard',
    targetID: '',
    fluid: false,
    icon: '',
  },
  {
    title: 'Gerenciador',
    url: '/manager',
    icon: '',
    fluid: false,
    targetID: '',
  },
];

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <SocketProvider>
      <Wrapper>
        <Menu menuList={menuList} fixedMenu={false} />
        {children}
    
        <Background />
       
        <Footer>
          <p>O I ENFAP é um evento do Confies e seu conteúdo não pode ser comercializado. Toda divulgação é permitida, desde que citada a fonte.</p>
        </Footer>
      </Wrapper>
      <Particles
        params={{
          particles: {
            lineLinked: {
              enable: true,
            },
            move: {
              speed: 1,
              direction: 'top-right',

              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },
            },
            shape: {
              type: 'circle',
           
            },
            color: { value: '#fff' },

            number: {
              density: {
                enable: true,
                value_area: 2400,
              },
              value: 160,
            },
            size: {
              value: 12,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 1
        }}
      />
    </SocketProvider>
  );
};

export default DefaultLayout;
