import React from 'react';

import { uuid } from 'uuidv4';
import {
  SearchComponent,
  ISearchComponent,
} from '../../components/SearchComponent';
import createModule from '../../components/SearchComponent/modules/Create';
import updateModule from '../../components/SearchComponent/modules/Update';
import updateUniqueModule from '../../components/SearchComponent/modules/UpdateUnique';

import deleteModule from '../../components/SearchComponent/modules/Delete';
import { Container, Header, Body } from './styles';
import api from '../../services/api';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const SponsorsAdmin: React.FC = () => {
  const endpoint = '/sponsors';

  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    position: { ref: 'position', column: 'position', label: 'Posição' },
    title: { ref: 'title', column: 'title', label: 'Categoria' },

    status: {
      ref: 'status',
      column: 'status_response',
      includeColumn: 'title',
      label: 'Status',
      filter: true,
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    position: {
      model: 'selectSimple',
      name: 'position',
      label: 'Posição',
      defaultValue: '',
      options: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
      ],
    },
    title: { model: 'input', type: 'text', name: 'title', label: 'Categoria' },
   

    status: {
      alias: 'status',
      model: 'selectApi',
      name: 'status',
      label: 'status',

      endpoint,
    },
  };

  const formSchemaUpdate = {

    size: {
      model: 'selectSimple',
      name: 'size',
      label: 'Tamanho',
      defaultValue: '',
      options: [
        { label: 'P', value: 'P' },
        { label: 'M', value: 'M' },
        { label: 'G', value: 'G' },
        { label: 'GG', value: 'GG' },
 
      ],
    },
    position: {
      model: 'selectSimple',
      name: 'position',
      label: 'Posição',
      defaultValue: '',
      options: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
      ],
    },
    title: { model: 'input', type: 'text', name: 'title', label: 'Categoria' },
   

    status: {
      alias: 'status',
      model: 'selectApi',
      name: 'status',
      label: 'status',

      endpoint,
    },
   
    sponsors: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'sponsors',
      label: 'Galeria',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Descritivo', name: 'description' },
        { label: 'Link', name: 'link_url' },
        { label: 'Tipo 1 Quadrado, 2 Retangular', name: 'type' },

      ],
    },
 
  };

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Patrocinadores',
      rules: [{ type: 'required' }],
    },
  };

  const key = uuid();

  const config: ISearchComponent = {
    idKey: key,
    title: 'Patrocinadores',
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        createModule({
          title: 'Patrocinadores',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        updateUniqueModule({
          lineData,
          title: 'Patrocinadores',
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        deleteModule({
          lineData,
          title: 'Patrocinadores',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default SponsorsAdmin;
