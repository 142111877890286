import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import { background } from '../../config';
import { ThemeDefinitions } from '../../styles/ThemeComponents';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const rotateSmoth = keyframes`
from {

  transform: rotateX(0deg) ;
}
to{
  transform: rotateX(20deg) ;
}
`;

interface IBackground {
  background?: string;
}

export const Container = styled.div<IBackground>`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  color: #555;
  padding: 100px 10px;
  width: 100%;
  max-width: 1000px;
  margin: auto;

  @media (max-width: 1200px) {
    padding: 0;
    margin: 0;
  }

  .sponsors {
    display: flex;
    width: 100%;
    max-width: 600px;
    background: #fff;
    justify-content: center;
    align-items: flex-start;
    margin: 30px 0;
    flex-wrap: wrap;
    @media (max-width: 1200px) {
      align-items: center;
      flex-direction: center;
  }

    > div {
      width: auto;
      max-width: 400px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: 1200px) {
        justify-content: center;
        flex-direction: center;
  }

      > h2 {
        font-size: 16px;

        color: rgb(50, 50, 50);
      }

      >div{
        position: relative;
        z-index:200;
        
        @media (max-width: 1200px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
      > img {
        width: 100%;
        margin: 20px;
      }

      > img.logoGerenciamento {
        margin-top: 50px;
        width: 70%;
      }

      >a {
        > img {
        width: 100%;
        margin: 20px;
      }

      > img.logoGerenciamento {
        margin-top: 50px;
        width: 70%;
      }
      }

      }
    }

    @media (max-width: 1200px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  > .blocoLogo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;

    .logoEvento {
      width: 100%;
      max-width: 500px;
      height: auto;
      transform: translateX(-5%);
    }

    .logoEventoMini {
      width: 100px;
      margin-top: 50px;
    }
  }

  .bloco {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;

    > a {
      margin: 10px;
    }

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    > a {
      > button {
        width: 200px;
        padding: 20px;
        text-align: center;
        background: none
        color: #fff;
        transition: background 0.5s;
        border: 0;
        border-radius: 8px;

        &:hover {
          background: none
        }
      }
    }
  }

  .separator {
    width: 100%;
    max-width: 200px;
    height: 2px;
    background: rgb(0, 150, 200);
    margin: 20px 0;
  }

  .borderedBottom {
    border: 2px solid #fff;
    padding: 10px;
    color: #fff;
    background: none;
    opacity: 0.8;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }
  }

  .logoEvento {
    width: 400px;
    max-width: 80%;
    z-index: 200;
  }

  .palestrantes {
    max-width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 1200px) {
      margin: 20px 0;
    }

    > .palestranteDiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > .palestrante {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        height: 100px;
        margin: 10px;
        border: 2px solid rgb(0, 150, 200);
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
        }
      }

      > h2 {
        font-size: 16px;
        font-weight: bold;
        color: rgb(0, 150, 200);
      }

      > p {
        font-size: 14px;

        color: rgb(0, 150, 200);
      }
    }
  }

  .paragraph {
    max-width: 500px;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 150, 200);
    }
  }
`;

export const ContainerWhite = styled(Container)`
  min-height: 90vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background: #fff;
  color: #555;
  padding: 100px 5%;
  width: 100%;
  max-width: 100%;
  margin: auto;

  @media (max-width: 1200px) {
    padding: 30px 20px;
    flex-direction: column;
  }

  > .p50 {
    z-index: 1;
    width: 50%;
    min-width: 50%;
    margin: 30px 0;

    @media (max-width: 1200px) {
      width: 100%;
      min-width: 100%;
    }

    > h2 {
      font-size: 1.5em;
    }

    > p {
      text-align: justify;
    }
  }

  .p600, .p500 {
    max-width: 600px;
    min-width: auto;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 150, 200);
    }

    > a {
      z-index: 2;
       >} button {
         margin-top: 10px;
      width: 200px;
      padding: 20px;
      text-align: center;
      background: rgb(0, 150, 200);
      color: #fff;
      transition: background 0.5s;
      border: 0;
      border-radius: 8px;

      &:hover {
        background: rgb(0, 120, 200);
      }
    }
  }

  .p500 {
      max-width: 400px;
      min-width: 200px;
      margin: auto;

      @media (max-width: 1200px) {
        max-width: 100%;
      min-width: 100%;
  }
    }


  }
`;

export const Capa = styled.div<IBackground>`
  min-height: 50vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  color: #555;
  padding: 10px 20px;

  width: 100%;
  max-width: 100%;
  margin: auto;


  @media (max-width: 1200px) {
    padding: 10px 0px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }


  .computerOnly {
    display: flex;
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .mobileOnly {
  display: none;
    @media (max-width: 1200px) {
      display: flex;
    }
  }
  }

  img.marlex {
    width: 170px;
    min-width: 170px;
    margin: 10px 15px;
    max-width: 170px;
    @media (max-width: 1200px) {
      width: 120px;

      min-width: 120px;
      margin: 5px;
      max-width: 120px;
    }
  }

  img.john {
    width: 250px;
    min-width: 250px;
    margin: 10px 15px;
    max-width: 250px;
    @media (max-width: 1200px) {
      width: 200px;

      min-width: 200px;
      margin: 5px;
      max-width: 200px;
    }

    @media (max-width: 750px) {
      width: 160px;

      min-width: 160px;
      margin: 5px;
      max-width: 160px;
    }
  }
  img.medtronic {
    width: 180px;
    min-width: 180px;
    max-width: 180px;
    margin: 10px 15px;
    @media (max-width: 1200px) {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      margin: 5px;
      height: auto;
    }
  }

  img.zanbon {
    width: 180px;
    min-width: 180px;
    max-width: 180px;
    margin: 10px 15px;
    @media (max-width: 1200px) {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      margin: 5px;
      height: auto;
    }
  }

  img.bellacqua {
    width: 180px;
    min-width: 180px;
    max-width: 180px;
    margin: 10px 15px;
    @media (max-width: 1200px) {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      margin: 5px;
      height: auto;
    }
  }

  .photoCoordenadores {
    display: flex;
    align-items: center;
    margin-top: 3px;
  }

  .buttonsActions {
    display: flex;
    align-items: center;
    margin-top: 3px;

    > a {
      max-width: 200px;
      width: 100%;
      margin: 5px;
      > button {
        font-size: 16px;
        border: 0px;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .noMargin {
    margin: 0px !important;
  }

  .justifyStart {
    justify-content: flex-start !important;

    @media (max-width: 1200px) {
      justify-content: center !important;
    }
  }

  .titleSkewRight {
    display: flex;
    transform: skewX(-10deg);
    background: rgb(0, 150, 200);
    transition: 0.5s all;
    padding: 10px 30px;
    justify-content: center;
    > h2,
    > button {
      transform: skewX(10deg);
      color: #fff;
    }

    &:hover {
      background: rgb(0, 120, 200);
      > h2,
      > button {
        background: rgb(0, 120, 200);
      }
    }
  }

  .sponsors {
    background: #fff;
    display: flex;
    width: 100%;

    justify-content: center;
    align-items: flex-start;

    flex-wrap: wrap;
    color: rgb(0, 120, 200);
    position: relative;
    z-index: 200;
    flex-direction: row;
    @media (max-width: 1200px) {
      align-items: center;
      flex-direction: column;
    }

    > div {
      width: auto;

      margin: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: 1200px) {
        justify-content: center;
        flex-direction: center;
      }
      > h2 {
        font-size: 16px;

        color: rgb(50, 50, 50);
      }
      > div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        min-height: 150px;

        @media (max-width: 1200px) {
          justify-content: center;
          align-items: center;
        }

        > img {
          margin: 10px;
        }

        > img.organizacao {
          width: 100px;
        }

        > a {
          > img {
            margin: 10px;
          }

          > img.organizacao {
            width: 100px;
          }
        }
      }
    }

    @media (max-width: 1200px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  > .chamada {
    margin: 5px;
    > h3 {
      font-size: 24px;
      color: #fff;
      margin-bottom: 20px;
      text-align: center;
      @media (max-width: 1200px) {
        font-size: 14px !important;

        margin: 10px;
      }

      > img {
        width: 30px;
        margin: 0px 10px;
      }
    }
  }

  > .blocoLogo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    z-index: 2;

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    > .p50 {
      z-index: 1;
      width: 50%;
      min-width: 50%;
      margin: 10px 0;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      @media (max-width: 1200px) {
        width: 100%;
      }

      > h2 {
        font-size: 1.5em;
      }

      > p {
        text-align: justify;
      }

      > .diamonds {
        width: auto;
        border-radius: 8px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        color: #fff;
        margin: 10px;

        background: #fff;
        padding: 50px;
        @media (max-width: 1200px) {
          padding: 20px;
          width: 100%;
          border-radius: 0px;
        }
        > h3 {
          font-size: 24px;
          color: rgb(0, 50, 100);
          margin-bottom: 20px;
          @media (max-width: 1200px) {
            font-size: 14px !important;
            color: rgb(0, 50, 100);
            margin: 10px;
          }
        }

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          > div {
          }
        }
      }
      > div {
        > .datasEvento {
          width: 100%;

          display: flex;
          justify-content: flex-start;
          align-items: center;

          color: #fff;
          margin: 10px;

          @media (max-width: 1200px) {
            justify-content: flex-start;
            width: auto;
            flex-direction: column;
          }

          > a {
            text-decoration: none;
            > h2 {
              min-width: 100px;
              font-size: 24px;
              min-height: 100px;
              border: 3px solid #fff;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0px 15px;
              color: #fff;

              > span {
                display: none;

                @media (max-width: 1200px) {
                  display: flex;
                  font-size: 10px;
                  align-items: center;
                  margin: 0 5px;
                }
              }

              @media (max-width: 1200px) {
                min-width: 60px;
                min-height: 0;
                font-size: 18px;
                border: 0px;
                border-bottom: 2px solid #fff;
                border-radius: 0px;
              }
            }
          }

          > div {
            > h3 {
              font-size: 24px;
              color: rgb(251, 200, 30);
              @media (max-width: 1200px) {
                font-size: 14px !important;
              }
            }

            > p {
              font-size: 14px;
              @media (max-width: 1200px) {
                font-size: 12px !important;
              }
            }
          }
        }
      }
    }

    .p600 {
      max-width: 600px;
      min-width: auto;
      width: 100%;

      font-size: 16px;

      > strong {
        font-weight: bold;
        color: rgb(0, 150, 200);
      }

      > a {
        z-index: 2;
      }
      button {
        margin-top: 10px;
        width: 200px;
        padding: 20px;
        text-align: center;
        background: rgb(0, 150, 200);
        color: #fff;
        transition: background 0.5s;
        border: 0;
        border-radius: 8px;

        &:hover {
          background: rgb(0, 120, 200);
        }
      }
    }

    .titleSkewRight,
    .titleSkewRightDark,
    .titleSkewRightWhite {
      display: flex;
      transform: skewX(-10deg);
      background: rgb(0, 150, 200);
      padding: 10px 30px;
      justify-content: center;
      text-decoration: none;
      transition: 0.5s all;
      > h2,
      > button {
        transform: skewX(10deg);
        color: #fff;
      }

      &:hover {
        background: rgb(0, 120, 200);
        > h2,
        > button {
          background: rgb(0, 120, 200);
        }
      }
    }
    .titleSkewRightWhite {
      background: #fff;
      align-items: flex-start;
      opacity: 1;
      > h2,
      > button {
        background: none;
        color: rgb(0, 30, 100);
      }

      &:hover {
        color: rgb(0, 50, 100);
        background: #fff;
        opacity: 0.8;
        > h2,
        > button {
          background: #fff;
          color: rgb(0, 50, 100);
        }
      }
    }

    .titleSkewRightDark {
      background: rgb(0, 50, 100);
      align-items: flex-start;
      > h2,
      > button {
        background: rgb(0, 50, 100);
      }

      &:hover {
        background: rgb(0, 30, 100);
        > h2,
        > button {
          background: rgb(0, 30, 100);
        }
      }
    }

    .widthAuto {
      width: auto;
    }

    .logoEvento {
      width: 100%;
      max-width: 500px;
      height: auto;
      transform: translateX(-5%);
    }

    .logoEventoMini {
      width: 100px;
      margin-top: 50px;
    }
  }

  .bloco {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;

    > a {
      margin: 10px;
    }

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    > a {
      > button {
        width: 200px;
        padding: 20px;
        text-align: center;
        background: rgb(0, 150, 200);
        color: #fff;
        transition: background 0.5s;
        border: 0;
        border-radius: 8px;

        &:hover {
          background: rgb(0, 120, 200);
        }
      }
    }
  }

  .separator {
    width: 100%;
    max-width: 200px;
    height: 2px;
    background: rgb(0, 150, 200);
    margin: 20px 0;
  }

  .borderedBottom {
    border: 2px solid #fff;
    padding: 10px;
    color: #fff;
    background: none;
    opacity: 0.8;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }
  }

  .logoEvento {
    width: 400px;
    max-width: 80%;
    z-index: 200;
  }

  .palestrantes {
    max-width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 1200px) {
      margin: 20px 0;
    }

    > .palestranteDiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > .palestrante {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        height: 100px;
        margin: 10px;
        border: 2px solid rgb(0, 150, 200);
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
        }
      }

      > h2 {
        font-size: 16px;
        font-weight: bold;
        color: rgb(0, 150, 200);
      }

      > p {
        font-size: 14px;

        color: rgb(0, 150, 200);
      }
    }
  }

  .paragraph {
    max-width: 500px;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 150, 200);
    }
  }
`;

export const SmallPhoto = styled.div<IBackground>`
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  margin-right: 10px;
  background: url(${props => props.background}) no-repeat top center;
  background-size: cover;
`;

export const Sponsors = styled.div`
  display: flex;
  width: 100%;

  background: #fff;
  color: rgb(0, 100, 150);
  justify-content: center;
  align-items: flex-start;

  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  z-index: 200;

  align-items: center;
  padding: 40px 20px;
  @media (max-width: 1200px) {
    align-items: center;
    flex-direction: column;
  }

  > div {
    width: auto;
    max-width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px) {
      justify-content: center;
      flex-direction: column;
    }

    > h2 {
      font-size: 16px;

      color: rgb(50, 50, 50);
    }

    > div {
      position: relative;
      z-index: 200;
      justify-content: center;
      align-items: center;
      margin: 20px;
      @media (max-width: 1200px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      > img {
        width: 100%;
        margin: 20px;

        @media (max-width: 1200px) {
          margin: 0px;
        }
      }

      > img.logoGerenciamento {
        margin-top: 50px;
        width: 70%;
      }

      > a {
        > img {
          width: 100%;
          margin: 20px;
        }

        > img.logoGerenciamento {
          margin-top: 50px;
          width: 70%;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Program = styled(Capa)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > div {
    > div {
      > h2 {
        font-size: 16px;
      }

      > table {
        border: 1px solid #fff;
        border-collapse: collapse;
        > thead {
          > th {
            border: 1px solid #fff;
            text-align: left;
            padding: 5px;
          }
        }

        tbody {
          > tr {
            > td {
              padding: 5px;
              border: 1px solid #fff;
            }
          }
        }
        width: 100%;
      }
    }
  }
`;
