import React, { useState, useRef, useEffect, useCallback, FC } from 'react';

import { ZoomMtg } from "@zoomus/websdk";
import {
  FiSearch,
  FiFile,
  FiChevronLeft,
  FiChevronRight,
} from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import {
  FaYoutube,
  FaTrophy,
  FaFilePdf,
  FaFileExcel,
  FaFilePowerpoint,
} from 'react-icons/fa';
import { userInfo } from 'os';
import { Link, useParams } from 'react-router-dom';
import { AiOutlineReload } from 'react-icons/ai';
import { uuid } from 'uuidv4';
import { LanguageSet } from '../Menu/styles';
import Button from '../Button';
import { useAuth } from '../../hooks/Auth';
import { useModal } from '../../hooks/Modal';
import { useToast } from '../../hooks/Toast';
import CreateModule from '../SearchComponent/modules/Create';
import  Sponsors  from '../../pages/Sponsors';

import { useSocket } from '../../hooks/Socket';

import QuizzComponent from '../QuizzComponent';

import {
  Form,
  Input,
  Container,
  Sidebar,
  Body,
  Header,
  Content,
  AnimatedContainer,
  VideoContainer,
  HeaderBox,
  SidebarMobileButton,
  Modal,
  ButtonColor,
  Card,ContainerSelector
} from './styles';
import SidebarContent from './Sidebar';
import HeaderContent from './Header';
import api from '../../services/api';
import { useVideo } from '../../hooks/Video';
import Vimeo from '../Vimeo';
import WebsitePopupComponent from '../WebsitePopupComponent';
import ChatRoom from '../ChatRoom';
import Youtube from '../Youtube';
import { defaultTheme, urlWebsite } from '../../config';

import { RoomMessagerContainer, RoomMessagerCreator } from '../RoomMessager';
import { RoomChangeContainer, RoomChangeCreator } from '../RoomChange';
import { useLanguage } from '../../hooks/Language';
import launchMeeting from '../ZoomStarter/index.js';
import ZoomContainer from '../ZoomContainerLink';
import Zoom from '../ZoomStarter'
import Program from '../Program';
import VideoPlayer from '../VideoPlayer';
import SorteioRoom from '../Sorteio';


interface IValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface IvideoIdItem {
  videoIdItem: string;
}

interface IValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<IValidationRules>;
}

interface IUploads {
  document: string;
  title?: string;
  id?: number;
  user_name?: string;
}

interface IUploadFile {
  title: string;
  type: 'PDF' | 'PPT' | 'XLS';
  url: string;
}

interface IVideos {
  before : any;
  waiting : any;
  online : any;

}

interface IBlock {
  block : IVideos;
  title : string;
}


const ClassRoomContainer: React.FC = () => {
  const {
    courses,
    setCoursesList,
    videoStatus,
    openVideo,
    setFilter,
    openVideoByID,
    openCategory,
  } = useVideo();

  const { socket } = useSocket();
  const { translate } = useLanguage();
  const { user, signOut, token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [eventDay, setEventDay] = useState(true);
  const [online, setOnline] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [activeMenu, setActiveMenu] = useState(false);
  const [programActive, setProgramActive] = useState(false);
  const { addToast } = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploads, setUploads] = useState<Array<IUploads>>([]);
  const [chatKey, setChatKey] = useState(() => new Date().getMilliseconds());

  const [allowQuizz, setAllowQuizz] = useState(true);

  const { addModal } = useModal();
  const [playerKey, setPlayerKey] = useState('videoPlayer');

  const getVideo = (type,id,class_name = '', time = '0') => { 

    if(type === 2){ return <Vimeo time={time} id={id} keyName={playerKey} />; }
    if(type === 1){ return <Youtube id={id} keyName={playerKey} />; }
    if(type === 3){ return <ZoomContainer id={id} class_name={class_name} keyName={playerKey} />; }
    if(type === 4){ return <VideoPlayer url={id} keyName={playerKey} />; }
  }


  const { videoIdItem } = useParams<IvideoIdItem>();
  const load = async () => {
    setLoading(true);
    const response = await api.get('/my-courses');
    const { data } = response;

    if (!data || data.length < 1) {
      setLoading(false);
      setOnline(false);
    } else {
      setLoading(false);
      setCoursesList(data || []);
      setOnline(true);
      if (videoIdItem ) {

        
        openVideoByID(data || [], parseInt(videoIdItem));
      }
    }
  };




  const [videos, setVideos] = useState<IVideos>({
    before : { title : 'Eventos anteriores', list : []},
    waiting : { title : 'Em breve', list : []},
    online : { title : 'Ao vivo agora', list : []},
  });

  useEffect(() => {

    const newVideos : IVideos =   {
          before : { title : 'Eventos anteriores', list : []},
          waiting : { title : 'Em breve', list : []},
          online : { title : 'Ao vivo agora', list : []},
        }
    
        { videoStatus &&
          videoStatus.type === 'list' &&
          courses[videoStatus.course] &&
          courses[videoStatus.course].lessons.map((lesson, index) => {
          
            if(lesson.status === 1){
              newVideos.waiting.list.push(lesson);
            }
          
            if(lesson.status === 2){
              newVideos.online.list.push(lesson);
            }
          
            if(lesson.status === 3){
              newVideos.before.list.push(lesson);
            }
          
          })
          
          };
    
          setVideos(newVideos);
    
      },[courses]);

      const printLesson = (lesson,index ) => {

        const statusSelector = {
          "1" : "Em breve",
        "2" : <><div className="redBall"/> Online agora</>,
        "3" : "Finalizado"
        }

        

return  (<AnimatedContainer style={{ display: lesson.visible === 2 ? 'flex' : 'none'}}
key={`videoLesson${lesson.id}`}
className={ `videoBox ${lesson.class_name && lesson.class_name} ${lesson.status && `status${lesson.status}`}`} 
onClick={() => openVideoByID(courses, lesson.id)}
background={
  lesson.image
    ? `${urlWebsite}/${lesson.image}`
    : '/apoio/card_padrao.png'
}
>

<div className="videoThumbHeader">
  <div>{lesson.start_hour} - { lesson.release_date_formatted} </div>
  <div className="videoStatus"> { statusSelector[lesson.status] || 'Em breve'}</div>
</div>
<div className="videoThumbHeader2" style={{background: lesson.room_color}}>
  <div>{lesson.room_name}  </div>
 
</div>

{lesson.image && (<>






</>)}

{!lesson.image && lesson.title && (
  <>
    <h2 dangerouslySetInnerHTML={{ __html: lesson.title }} />

  </>
)}
</AnimatedContainer> || <></>
)
      }

  const reloadChat = () => {
    setChatKey(new Date().getMilliseconds());
  };

  const reloadPlayer = () => {
    const d = new Date();
    const n = d.getTime();
    setPlayerKey(`video${n}`);
  };

  useEffect(() => {
    reloadChat();
    load();
    socket.on('disconnectUser', () => {
      console.log('disconnect me');
      addToast({
        type: 'error',
        title: translate('Você foi desconectado'),
        description: translate(
          'Seu token expirou ou outra pessoa logou com o seu acesso.',
        ),
      });
      setTimeout(() => {
        signOut();
        window.location.href = '/';
      }, 3000);
    });

    socket.on('checkDisconnectUser', state => {
      console.log('check disconnect me', state);
      if (token !== state.token) {
        addToast({
          type: 'error',
          title: translate('Você foi desconectado'),
          description: translate(
            'Seu token expirou ou outra pessoa logou com o seu acesso.',
          ),
        });
        setTimeout(() => {
          signOut();
          window.location.href = '/';
        }, 3000);
      }
    });
    socket.emit('checkUser');
  }, []);

  useEffect(() => {
    reloadChat();
    load();
    setProgramActive(false);
  }, [videoIdItem]);

  const handleSearch = (data: Record<string, any>): void => {
    setFilter(data.search || '');
  };

  const FormVideo: React.FC = () => {
    const handleNewVideo = (data: Record<string, any>) => {};
    const endpoint = '/lessons-simple';

    const formSchema = {
      title: {
        model: 'input',
        name: 'title',
        label: 'Título',
        defaultValue: '',
      },

      video_type_id: {
        alias: 'video_type',
        model: 'selectApi',
        name: 'video_type_id',
        label: 'Tipo de Vídeo',
        defaultValue: 1,
        endpoint,
      },
      video_id: {
        model: 'input',
        name: 'video_id',
        label: 'Código do Vídeo',
        defaultValue: '',
      },
    };

    const validationSchema: Record<string, IValidationSchema> = {
      title: {
        name: 'title',
        type: 'string',
        label: 'Título',
        rules: [{ type: 'required' }],
      },
      video_id: {
        name: 'video_id',
        type: 'string',
        label: 'Código do Vìdeo',
        rules: [{ type: 'required' }],
      },
    };

    return (
      <CreateModule
        title="Adicionar Vídeo"
        formSchema={formSchema}
        validationSchema={validationSchema}
        endpoint={endpoint}
        buttonContent={(
          <div
            style={{
              color: 'red',
              fontSize: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FaYoutube />
          </div>
        )}
        addCallback={load}
      />
    );
  };

  const ListRoomByCategory = (column, selector) => {
    const statusSelector = {
      "1" : "Em breve",
    "2" : <><div className="redBall"/> Online agora</>,
    "3" : "Finalizado"
    }


    
        return (
          (videoStatus &&
            videoStatus.type === 'list' &&
            courses[videoStatus.course] &&
            courses[videoStatus.course].lessons.map((lesson, index) => (
              lesson[column] && lesson[column] === selector ?
              <AnimatedContainer
                key={`videoLesson${lesson.id}`}
                className={ `videoBox ${lesson.class_name && lesson.class_name} ${lesson.status && `status${lesson.status}`}`} 
                onClick={() => openVideo(courses, videoStatus.course, index)}
                background={
                  lesson.image
                    ? `${urlWebsite}/${lesson.image}`
                    : '/apoio/card_padrao.png'
                }
              >
    
                <div className="videoThumbHeader">
                  <div>{lesson.start_hour} - { lesson.release_date_formatted} </div>
                  <div className="videoStatus"> { statusSelector[lesson.status] || 'Em breve'}</div>
                </div>
                <div className="videoThumbHeader2" style={{background: lesson.room_color}}>
                  <div>{lesson.room_name}  </div>
                 
                </div>
                
                {lesson.image && (<>
              
        
    

    
    
                </>)}
    
                {!lesson.image && lesson.title && (
                  <>
                    <h2 dangerouslySetInnerHTML={{ __html: lesson.title }} />
                    <ButtonColor background={lesson.room_color}>
                      {lesson.room_name}
    {' '}
    |{lesson.release_date_formatted}
    {' '}
    -
    {lesson.start_hour}
                    </ButtonColor>
                  </>
                )}
              </AnimatedContainer> : <></>
            ))) || <></>
        );
      };

      const createRoomSelectors = () => {

        const selectors = [
          { column : 'status', selector: 2, title: "Ao Vivo"},
          { column : 'room_name', selector: 'Espaço 1', title: 'Espaço 1' },
          { column : 'room_name', selector: 'Espaço 2', title: 'Espaço 2'},
          { column : 'room_name', selector: 'Espaço 3', title: 'Espaço 3'},
          { column : 'room_name', selector: 'Espaço 4', title: 'Espaço 4'},
          { column : 'room_name', selector: 'Espaço 5', title: 'Espaço 5'},
          { column : 'room_name', selector: 'Espaço 6', title: 'Espaço 6'}
        ];

        const response = selectors.map( category => <div><h2>{category.title}</h2><div>{ListRoomByCategory(category.column, category.selector)}</div></div>);
        return <ContainerSelector>{response} </ContainerSelector>|| <></>;
      }


    const listBlock = ( { block , title }  ) => {
      return ( <> 
        {videoStatus &&
          videoStatus.type === 'list' && block &&
          block.list &&
          block.list.length > 0 && 
          <div style={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <h2 style={{padding:'10px'}}>{title}</h2>
          <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center'}}>
  { block.list.map((lesson, index) => (
           printLesson(lesson,index)))}
    </div></div>
      }
  
  
      </>)
    }


  const ListRoom = () => {

    return (<>
    {videos.online  && listBlock({ block : videos.online, title : translate('Online Agora')}) }
    {videos.waiting && listBlock({ block : videos.waiting, title : translate('Em breve')}) }
    {videos.before && listBlock({ block : videos.before, title : translate('Vídeos Anteriores')}) }
    
    </>);
   
  };

  const [option, setOption] = useState(1);

const getList = () : JSX.Element => {
 
return ( videoStatus &&
  videoStatus.type === 'list' &&
  courses[videoStatus.course] ? <> <div className="buttonDiv"> <div>
<button type="button" onClick={ () => setOption(1)} className={option === 1 ? 'squareButton' : "squareButtonReverse"}>Ver todos conteúdos</button>
<button type="button" onClick={ () => setOption(2)} className={option === 2 ? 'squareButton' : "squareButtonReverse"}><div className="redBall"/> <span> Ver Online Agora</span></button>
</div>
</div>
{option === 1 ? ListRoom() : ListRoomByCategory('status',2)}

</> : <></>
)



}

  const getLanguages = (courseIndex, lesson) => {
    if (lesson.languages && (lesson.languages.en || lesson.languages.es)) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <p style={{color : defaultTheme.defaultBackground}}>{translate('Selecionar idioma do Vídeo')}: </p>
          <LanguageSet>
            <div onClick={() => openVideoByID(courses, lesson.languages.ptBr)}>
              <img
                style={{ margin: 0 }}
                src="/assets/languages/BR.png"
                alt="Portuguese"
              />
            </div>
            {lesson.languages.en && (
              <div onClick={() => openVideoByID(courses, lesson.languages.en)}>
                <img
                  style={{ margin: 0 }}
                  src="/assets/languages/US.png"
                  alt="English"
                />
              </div>
            )}
            {lesson.languages.es && (
              <div onClick={() => openVideoByID(courses, lesson.languages.es)}>
                <img
                  style={{ margin: 0 }}
                  src="/assets/languages/ES.png"
                  alt="Spanish"
                />
              </div>
            )}
          </LanguageSet>
        </div>
      );
    }

    return <></>;
  };

  const OpenRoom = () => {
    const validTypes = {
      PDF: <FaFilePdf />,
      XLSX: <FaFileExcel />,
      PPT: <FaFilePowerpoint />,
    };

    const uploadFiles: Array<IUploadFile> =
      videoStatus &&
      videoStatus.type === 'video' &&
      courses[videoStatus.course] &&
      courses[videoStatus.course].lessons &&
      courses[videoStatus.course].lessons[videoStatus.video] &&
      courses[videoStatus.course].lessons[videoStatus.video].uploads
        ? courses[videoStatus.course].lessons[videoStatus.video].uploads || []
        : [];

    return (
      (videoStatus &&
        videoStatus.type === 'video' &&
        courses[videoStatus.course] &&
        courses[videoStatus.course].lessons &&
        courses[videoStatus.course].lessons[videoStatus.video] && (
          <>
          <div style={{minWidth:'50%'}}></div>
            <div className="videoGroup">
              <VideoContainer>
                <h2  style={{ width: '100%', textAlign: 'center' }}><div/>
                  <div className="defaultMarked"><div/>{courses[videoStatus.course].lessons[videoStatus.video].title}</div>
                  { user.profile !== 9 && getLanguages(
                    videoStatus.course,
                    courses[videoStatus.course].lessons[videoStatus.video],
                  )}
                </h2>

                { courses[videoStatus.course].lessons[videoStatus.video].room_name === 'Espaço 1' && courses[videoStatus.course].lessons[videoStatus.video].status === 3 && courses[videoStatus.course].lessons[videoStatus.video]
                            .time &&
                  <p style={{fontSize: '14px', textAlign:'center', background: 'coral', color: '#fff', padding: '10px', margin:"5px"}}>
                 
                  {courses[videoStatus.course].lessons[videoStatus.video]
                            .time &&  <> {`Esta palestra inicia aos ${courses[videoStatus.course].lessons[videoStatus.video]
                              .time} do vídeo`}</>}
                  </p>
                }

           <RoomMessagerContainer
                  room_id={
                    courses &&
                    courses[videoStatus.course] &&
                    courses[videoStatus.course].lessons &&
                    courses[videoStatus.course].lessons[videoStatus.video]
                      ? courses[videoStatus.course].lessons[videoStatus.video]
                          .id
                      : 0
                  }
                  module_id={1}
                /> 
               <HeaderBox>
                  {videoStatus.prevVideo ? (
                    <button
                      className="defaultButton"
                      type="button"
                      onClick={() => {
                        videoStatus.prevVideo &&
                          openVideo(
                            courses,
                            videoStatus.prevVideo.courseIndex,
                            videoStatus.prevVideo.videoIndex,
                          );
                      }}
                    >
                      <FiChevronLeft />
                      Anterior
                    </button>
                  ) : (
                    <button type="button" className="defaultButton" />
                  )}
                  <button
                    onClick={() => openCategory(0, 0)}
                    type="button"
                    className="defaultButton"
                  >
                    + Palestras
                  </button>
                
                  {videoStatus.nextVideo ? (
                    <button
                      className="defaultButton"
                      type="button"
                      onClick={() => {
                        videoStatus.nextVideo &&
                          openVideo(
                            courses,
                            videoStatus.nextVideo.courseIndex,
                            videoStatus.nextVideo.videoIndex,
                          );
                      }}
                    >
                      Próximo
                      <FiChevronRight />
                    </button>
                  ) : (
                    <button type="button" className="defaultButton" />
                  )}
                </HeaderBox>

                {user &&  user.profile === 9 && (
<Zoom room_id={courses[videoStatus.course].lessons[videoStatus.video]
                        .id} />
                )

                }
                {user && user.profile !== 6 &&  user.profile !== 9 && (
                  <>
                   <div className="videoContainer">
                      {getVideo( courses[videoStatus.course].lessons[videoStatus.video]
                        .video_type_id ,courses[videoStatus.course].lessons[
                          videoStatus.video
                        ].video_id, courses[videoStatus.course].lessons[videoStatus.video]
                        .class_name,
                        courses[videoStatus.course].lessons[videoStatus.video]
                        .time || '0'
                        )}
                     
   
                    </div>
                    {courses[videoStatus.course].lessons[videoStatus.video]
                        .video_type_id !== 3 &&  <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                      <button
                          style={{ margin: '10px', background: '#fff' }}
                          title="Player not working? Click here!"
                          className="reloadPlayer"
                          onClick={() => reloadPlayer()}
                        >
                          <AiOutlineReload />
{' '}
Recarregar vídeo
</button>
                      </div>}
                  </>
                )}
              </VideoContainer>
              {courses[videoStatus.course].lessons[videoStatus.video]
                        .video_type_id !== 3 && 
              <div style={{ position: 'relative' }}>
                <QuizzComponent
                  roomNumber={
                    courses[videoStatus.course].lessons[videoStatus.video].id
                  }
                />

               <ChatRoom
                  id={courses[videoStatus.course].lessons[videoStatus.video].chat_id || courses[videoStatus.course].lessons[videoStatus.video].id}
                />

                {user && user.profile === 1 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '5px',
                    }}
                  >
                    <WebsitePopupComponent
                      roomNumber={
                        courses &&
                        courses[videoStatus.course] &&
                        courses[videoStatus.course].lessons &&
                        courses[videoStatus.course].lessons[videoStatus.video]
                          ? courses[videoStatus.course].lessons[
                              videoStatus.video
                            ].id
                          : 0
                      }
                    />
                    <RoomChangeCreator
                      room_id={
                        courses &&
                        courses[videoStatus.course] &&
                        courses[videoStatus.course].lessons &&
                        courses[videoStatus.course].lessons[videoStatus.video]
                          ? courses[videoStatus.course].lessons[
                              videoStatus.video
                            ].id
                          : 0
                      }
                      module_id={1}
                    />
                  </div>
                )}

                {user &&
                  (user.profile === 1 ||
                    user.profile === 4 ||
                    user.profile === 6) && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '5px',
                      }}
                    >
                           <SorteioRoom id={courses &&
                          courses[videoStatus.course] &&
                          courses[videoStatus.course].lessons &&
                          courses[videoStatus.course].lessons[videoStatus.video]
                            ? courses[videoStatus.course].lessons[
                                videoStatus.video
                              ].id
                            : 0}/>
                      <RoomMessagerCreator
                        room_id={
                          courses &&
                          courses[videoStatus.course] &&
                          courses[videoStatus.course].lessons &&
                          courses[videoStatus.course].lessons[videoStatus.video]
                            ? courses[videoStatus.course].lessons[
                                videoStatus.video
                              ].id
                            : 0
                        }
                        module_id={1}
                      />
                    </div>
                  )}
              </div>}
            </div>
          </>
        )) || <></>
    );
  };

  const showTrophy = () => {
    addToast({
      type: 'info',
      title: 'Nenhum troféu identificado',
      description: 'Continue sua jornada para alcançar novas conquistas',
    });
  };

  if (loading) {
    return <Container />;
  }

  return (<>
    <Container>
      
      <Content>
       
        <Body >
   
          {eventDay || user.profile === 1 || user.profile > 2  ? (
            <>
         
          
              {ListRoom()}
              {OpenRoom()}
            </>
          ) : 

             <div className="onlineMessage">
           
            <h2 className="defaultMarked" style={{color:'#333'}}><div/>{translate('Inscrição Confirmada')}</h2>
            <p style={{color: '#333', textAlign: 'center',marginTop: '20px'}}>
              {translate(
                'Retorne a este website no dia do evento para participar do evento.',
              )}
            </p><br/><br/>
            <Link to="/">
              <button className="defaultButton" type="button">
                {' '}
                {translate('Sair')}
              </button>
            </Link>
          </div> 
            
            
            
          }
        </Body>
      </Content>

      {programActive && (
        <Modal top right>
          <div
            style={{
              display: 'block',
            }}
          >
            <Button onClick={() => setProgramActive(false)}>
            {translate('Fechar')}
{' '}
            </Button>

            <Program  />
          </div>
        </Modal>
      )}
      <RoomChangeContainer />
    </Container>
          <Sponsors/>
    </>
  );
};

export default ClassRoomContainer;
