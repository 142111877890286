import React, { useEffect, useState } from 'react';

import {
  FaWhatsapp,
  FaTelegram,
  FaInstagram,
  FaFacebookSquare,
  FaHamburger,
  FaSignInAlt,
  FaSignOutAlt,
  FaSign,
} from 'react-icons/fa';

import { MdMenu } from 'react-icons/md';

import { useAuth } from '../../hooks/Auth';
import { eventName, logo, social } from '../../config';
import {
  Container,
  Navigation,
  NavigationList,
  LinkLi as Link,
  MenuIcon,
  Icon,
  MobileMenuBackground,
  TextMessage,
  IconSet,
} from './styles';

import Whatsapp from '../Whatsapp';

interface IOffset extends Element {
  offsetTop: number;
}

export function scrollTo(
  e2: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  targetID2: string,
  duration2 = 400,
): void {
  const scrollToTarget = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    targetID: string,
    duration = 400,
  ): void => {
    e.preventDefault();
    const target = document.querySelector<IOffset>(`#${targetID}`);
    const position = target && target.offsetTop ? target.offsetTop : 0;
    const offset = position - 200;

    const startPosition = window.pageYOffset;

    const distance = offset - startPosition;
    let startTime = 0;

    const ease = (t: number, b: number, c: number, d: number): number => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animation = (currentTime = 0): void => {
      if (startTime === 0) {
        startTime = currentTime;
      }

      const timeElapsed = currentTime - startTime;

      const run = ease(timeElapsed, startPosition, distance, duration);

      window.scrollTo(0, run);

      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    };
    requestAnimationFrame(animation);
  };

  scrollToTarget(e2, targetID2, duration2);
}

const WhatsappIcon: React.FC = () => {
  return (
    <Icon
      style={{ margin: '0px' }}
      background="rgb(0,150,100)"
      title="Whatsapp"
      href="https://api.whatsapp.com/send?l=pt&phone=5551981476007"
    >
      <FaWhatsapp />
    </Icon>
  );
};

const LoginIcons: React.FC = () => {
  const { signOut, token } = useAuth();

  return (
    <>
      {' '}
      <li key="login">
        <a href="/login" title="Login">
          Login
          <FaSignInAlt />
        </a>
      </li>
      {token && (
        <li key="logout">
          <Link
            onClick={e => {
              signOut();
            }}
            to="/"
            title="Sair"
          >
            Sair
            <FaSignOutAlt />
          </Link>
        </li>
      )}
    </>
  );
};

interface IIcons {
  message?: string;
  showText?: boolean;
  backgroundFill?: boolean;
  socialIcons?: Record<string, any>;
}

const ContactIcons: React.FC<IIcons> = ({
  message = '',
  showText = false,
  backgroundFill = true,
  socialIcons = social,
}) => {
  return (
    <IconSet column={showText}>
      {message !== '' && (
        <div className="messageSet">
          <TextMessage>{message}</TextMessage>
        </div>
      )}
      <div className="iconSet">
        {social.facebook && (
          <Icon
            target="_blank"
            extend={showText}
            className="socialIcon"
            title="Facebook"
            href={`https://www.facebook.com/${social.facebook}`}
            background={backgroundFill === true ? '#3d6ad6' : 'none'}
          >
            <FaFacebookSquare />
{' '}
{showText && <span>Facebook</span>}
          </Icon>
        )}

        {social.instagram && (
          <Icon
            extend={showText}
            className="socialIcon"
            title="Instagram"
            target="_blank"
            href={`https://www.instagram.com/${social.instagram}`}
            background={backgroundFill === true ? 'rgb(250,0,100)' : 'none'}
          >
            <FaInstagram />
{' '}
{showText && <span>Instagram</span>}
          </Icon>
        )}

        {social.whatsapp && (
          <Icon
            extend={showText}
            target="_blank"
            className="socialIcon"
            background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
            title="Whatsapp"
            href={`https://api.whatsapp.com/send?l=pt&phone=55${
              social.whatsapp
            }&text=${encodeURI(`Olá! Estou no evento ${eventName}!`)}`}
          >
            <FaWhatsapp />

            {showText && <span>Whatsapp</span>}
          </Icon>
        )}
      </div>
    </IconSet>
  );
};

interface IMenu {
  menuList: Record<string, any>;
  fixedMenu: boolean;
}

const Menu: React.FC<IMenu> = ({ menuList, fixedMenu = true }) => {
  const [sticky, setSticky] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    if (fixedMenu) {
      const header = document.querySelector<IOffset>('.menuHeader');

      const offsetValue = header?.offsetTop || 0;

      const offsetMonitor = (): void => {
        if (window.pageYOffset > offsetValue) {
          setSticky(true);
        } else {
          setSticky(false);
        }
      };

      window.onscroll = () => {
        offsetMonitor();
      };
    }
  }, []);

  const handleActiveMenu = (): void => {
    setActiveMenu(!activeMenu);
  };

  return (
    <Container
      sticky={sticky}
      fixedMenu={fixedMenu}
      activeMenu={activeMenu}
      className="menuHeader"
    >
      <Navigation activeMenu={activeMenu} sticky={sticky} fixedMenu={fixedMenu}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        {window.location.pathname !== "/" && <img src="/assets/evento/logo.png" />}
        </div>
        <MobileMenuBackground activeMenu={activeMenu} />
        <NavigationList activeMenu={activeMenu}>
          {menuList.map((menu: Record<string, any>) => {
            const {
              title,
              url,
              icon,
              targetID = null,
              fluid = false,
              external = false,
            } = menu;

            const LinkMe: React.FC = () => {
              if (fluid) {
                return (
                  <Link
                    onClick={e => {
                      if (window.location.pathname === '/') {
                        setActiveMenu(false);
                        scrollTo(e, targetID);
                      } else {
                        setActiveMenu(false);
                        window.location.href = `${url}#${targetID}`;
                      }
                    }}
                    to={url}
                    title={title}
                  >
                    {icon}
                    {title}
                  </Link>
                );
              }
              if (external) {
                return (
                  <a
                    title={title}
                    href={url}
                    onClick={() => setActiveMenu(false)}
                  >
                    {icon}

                    {title}
                  </a>
                );
              }

              return (
                <Link
                  title={title}
                  to={url}
                  onClick={() => setActiveMenu(false)}
                >
                  {icon}

                  {title}
                </Link>
              );
            };

            return (
              <li key={url}>
                <LinkMe />
              </li>
            );
          })}

          <li className="closeMenu" onClick={() => handleActiveMenu()}>
            {'<<'}
            Voltar
          </li>

          <div className="row">
            <LoginIcons />
            <ContactIcons />
          </div>
        </NavigationList>

        <MenuIcon onClick={() => handleActiveMenu()}>
          <MdMenu size="40px" />
        </MenuIcon>
      </Navigation>
    </Container>
  );
};

export { WhatsappIcon, ContactIcons, Menu };
