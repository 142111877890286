import React, { useState, createContext, useContext, useEffect } from 'react';

import { apiUrl, apiSocket } from '../config';
import { useAuth } from './Auth';

interface IPagesContextData {
  menu : Array<Record<string, any>>;
  pages : Array<Record<string, any>>;
  readPages : Function;
}

const PagesContext = createContext<IPagesContextData>(
  {} as IPagesContextData,
);


const PagesProvider: React.FC = ({ children }) => {

  const [pages, setPages] = useState(
  [
   { tag: 'home', status : 2},
   { tag: 'patrocinadores', status : 1},
   {tag: 'programacao', status : 1},
   {tag: 'sobre', status : 1},
   {tag: 'palestrantes', status : 1},
   {tag: 'login', status : 2},
   {tag: 'inscricao', status : 2} ]
  );

  const [menu, setMenu] = useState(
    [
    { title: 'EVENTO', target: '/', target_id: 'sobre', fluid: 2, external: 1, status : 1},
    { title: 'COMISSÃO', target: '/', target_id: 'comissao', fluid: 2, external: 1, status : 1},
    { title: 'PROGRAMAÇÃO', target: '/', target_id: 'programacao', fluid: 2, external: 1, status : 2},
    { title: 'PALESTRANTES', target: '/', target_id: 'palestrantes', fluid: 2, external: 1, status : 1},
    { title: 'INSCRIÇÃO', target: '/inscricao', target_id: '', fluid: 1, external: 1, status : 2},
    { title: 'SEJA UM PATROCINADOR', target: '/seja_um_patrocinador', target_id: '', fluid: 1, external: 1, status : 1},
    { title: 'ÁREA DE EXPOSIÇÃO', target: '/exposicao', target_id: '', fluid: 1, external: 1, status : 1},
    { title: 'CERTIFICADOS', target: '/certificados', target_id: '', fluid: 1, external: 1, status : 2},
  ]
  );

  const readPages = () => {

    const pagesResponse = pages.reduce((prev,elem) => {

      prev[elem.tag] = elem;

      return prev;

    }, {});

    return pagesResponse;

  }


  return (
    <PagesContext.Provider value={{ pages, menu, readPages }}>
      {children}
    </PagesContext.Provider>
  );
};

function usePages(): IPagesContextData {
  const context = useContext(PagesContext);

  if (!context) {
    throw new Error('usePages must be used within a PagesProvider');
  }

  return context;
}

export { usePages, PagesProvider };
