import React, { useEffect } from 'react';
import { Wrapper, Background, Footer } from './styles';
import { Pattern } from '../../../styles/ThemeComponents';
import Header from '../../../components/Header';
import { Menu } from '../../../components/Menu';
import { SocketProvider } from '../../../hooks/Socket';
import { useAuth } from '../../../hooks/Auth';

const menuList = [
  {
    title: 'Home',
    url: '/',
    icon: '',
    fluid: true,
    external: false,
    targetID: 'root',
  },
  {
    title: 'Comissão',
    url: '/',
    icon: '',
    fluid: true,
    external: false,
    targetID: 'comissao',
  },
  {
    title: 'Programação',
    url: '/',
    icon: '',
    fluid: true,
    external: false,
    targetID: 'programacao',
  },
  {
    title: 'Inscrição',
    url: '/inscricao',
    icon: '',
    fluid: false,
    external: false,
    targetID: '',
  },
];

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <SocketProvider>
      <Wrapper>
        <Menu menuList={menuList} fixedMenu />
        {children}

       
      </Wrapper>
    </SocketProvider>
  );
};

export default DefaultLayout;
