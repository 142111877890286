import React, { useCallback, useRef, useEffect, useState } from 'react';

import { FormHandles } from '@unform/core';

import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';
import {  scrollTo } from '../../components/Menu';

import {
  ContainerWhite,
  Capa,
} from './styles';

import SponsorsContainer from '../Sponsors';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import Programacao from '../Program';
import Speakers from '../Speakers';
import Comissao from '../Comissao';
import Direcao from '../Direcao';
import { useModal } from '../../hooks/Modal';
import { FaCentercode } from 'react-icons/fa';

const SignIn: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [cardLayout, setCardLayout] = useState([] as Array<JSX.Element>);
  const { addModal, removeModal } = useModal();

  useEffect(() => {
  /* addModal({
      key: 'popup',
      title: '',
      content: (
        <>
          <h3 style={{ marginBottom: '10px', color: '#333', textAlign: 'center' }}>
            A área de palestras
            <br />
{' '}
já está aberta.
</h3>
          <Link
            style={{ width: '100%' }}
            to="/login"
            onClick={() => removeModal('popup')}
          >
            <button
              style={{ width: '100%' }}
              className="defaultButton"
              type="button"
            >
              {' '}
              Acesse com seu email
{' '}
            </button>
          </Link>
          <Link
            style={{ width: '100%' }}
            to="/inscricao"
            onClick={() => removeModal('popup')}
          >
            <button
              style={{ width: '100%' }}
              className="defaultButton"
              type="button"
            >
              {' '}
              Inscreva-se!
{' '}
            </button>
          </Link>
        </>
      ),
    }); */
  }, []);

  return (
    <>
      <Capa>
        
   
          <div className=" " style={{padding: '0px 20px', paddingBottom: '30px', minWidth: '35%', maxWidth: '100%' }}>
          

        
            <div>
              <div className="datasEvento">
            <img src="/assets/evento/logo.png" style={{width: '100%', maxWidth: '800px', margin: '0px auto'}}/>
                <div style={{textAlign: 'center'}}>
                  <h1 style={{fontSize: '40px', fontWeight: 'bold', color: '#333',textAlign:'center'}}>23 e 24 de junho de 2021</h1>
                  <h2 style={{ textAlign:'center', marginBottom: '20px', color:'#333'}}>
EVENTO TOTALMENTE ON-LINE E GRATUITO</h2>
<Link to="/certificados">
            <button
              style={{
           
                margin: '2px',
         
                fontSize: '16px',
                maxWidth: '170px',
          
              }}
              className="defaultButton"
              type="button"
            >
              {' '}Acesse seu certificado!<br/>
            
            </button>
          </Link>
<div style={{padding:'10px', display:'flex', alignItems:'center', flexDirection:'column', border:'2px solid #333', borderRadius:'10px'}}>
<h2>Inscrição</h2>
         <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
                  <Link to="/inscricao">
            <button
              style={{
           
                margin: '2px',
         
                fontSize: '16px',
                maxWidth: '170px',
          
              }}
              className="defaultButton"
              type="button"
            >
              {' '}Fundações<br/>
            
            </button>
          </Link>
          <Link to="/inscricao2">
            <button
              style={{
           
                margin: '2px',
         
                fontSize: '16px',
                maxWidth: '170px',
          
              }}
              className="defaultButton"
              type="button"
            >
             Público externo <br/>
        
            </button>
          </Link></div></div>
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
          <Link to="/login">
            <button
              style={{
           
                margin: '2px',
              
                fontSize: '16px',
                maxWidth: '170px',
              }}
              className="defaultButton"
              type="button"
            >
              {' '}
              Já sou inscrito
            </button>
          </Link>
          <Link to="/" onClick={(e) => { e.preventDefault(); scrollTo(e,'programacao',400)}}>
            <button
              style={{
            
                margin: '2px',
     
                fontSize: '16px',
                maxWidth: '170px',
              
              }}
              className="defaultButton"
              type="button"
            >
              {' '}
              Programação
            </button>
          </Link></div>
                </div>
              
              </div>
            </div>
          </div>

          <div className="" style={{maxWidth: '1400px'}}>
          <SponsorsContainer/>

        </div>
     
        
     
    


   
   

      </Capa>
     
      <Capa id="programacao" style={{padding: '0px'}}>
  <Programacao/>
</Capa>
  




     
    </>
  );
};
export default SignIn;
