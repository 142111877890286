/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, Dispatch, useRef, useMemo, useState } from 'react';
import { Form } from '@unform/web';
import { FiArrowRight, FiArrowLeft, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import axios from 'axios';
import pagarme from 'pagarme';
import { type } from 'os';
import { Link } from 'react-router-dom';
import api from '../../../../services/api';
import MaskedInput from '../../../../components/MaskedInput';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import SelectSimple from '../../../../components/SelectSimple';

import imgCreditCard from '../../../../assets/credit_card.png';
import imgBoleto from '../../../../assets/barcode.png';

import { AnimatedDiv } from '../../styles';
import { Content } from './styles';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';
import { gateKey } from '../../../../config';

import { BuyContext } from '../../index';
import Loader from '../../../../components/Loader';
import format from '../../../../utils/format';
import {
  ResponseMessage,
  IResponseMessage,
} from '../../../../components/ResponseMessage';

interface IComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
}

interface ICardProps {
  id?: string;
  card_holder_name: string;
  card_expiration_date: string;
  card_number: string;
  card_cvv: string;
  installments?: number;
}

interface IInstallment {
  label: string;
  value: string;
}

const PaymentMethod: React.FC<IComponentProps> = ({
  buyContext,
  setBuyContext,
  setStep,
  step,
}) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { addModal } = useModal();
  const [responseMessage, setResponseMessage] = useState(
    {} as IResponseMessage,
  );

  const handleChangePaymentMethod = useCallback(
    id => {
      setBuyContext({ ...buyContext, billing: { paymentMethod: id } });
    },
    [buyContext, setBuyContext],
  );

  const installments = useMemo(() => {
    const installment = [] as Array<IInstallment>;
    for (let x = 1; x <= 10; x += 1) {
      installment.push({
        label: `${x}X ${format(
          parseInt((buyContext.amount / x).toString(), 10),
        )}`,
        value: x.toString(),
      });
    }
    return installment;
  }, [buyContext]);

  const handleFinishCreditCard = useCallback(
    async (card: ICardProps) => {
      setLoading(true);
      const cardValidations = await pagarme.validate({ card });

      if (!cardValidations.card.card_number) {
        addModal({
          title: 'Cartão inválido',
          content: <>Verifique o número do seu cartão</>,
        });
        setLoading(false);
        return false;
      }

      await pagarme.client
        .connect({ encryption_key: gateKey })
        .then(async (client: Record<string, any>) => {
          const card_hash = await client.security.encrypt(card);

          return card_hash;
        })
        .then(async (card_hash: string) => {
          if (!card_hash) {
            setLoading(false);
            return false;
          }

          const context = {
            ...buyContext,
            billing: {
              paymentMethod: 'credit_card',
              credit_card_hash: card_hash,
              installments: card.installments,
            },
          };

          await api
            .post(`/payment`, {
              ...context,
            })
            .then(response => {
              const { data } = response;

              if (data && data.status === 'success') {
                setResponseMessage({
                  icon: () => <FiMail />,
                  active: true,
                  title: 'Compra realizada com sucesso!',
                  type: 'success',
                  description: (
                    <>
                      Sua compra foi confirmada!
                      <br />
                      Para acessar a plataforma, clique no botão abaixo
                      <Link to="/login">
                        <Button type="button">Fazer Login </Button>
                      </Link>
                    </>
                  ),
                });
              } else {
                addModal({ title: 'Erro', content: data.message, key: '1' });
              }
              setLoading(false);
            })
            .catch(err => {
              addToast({
                title: 'Erro',
                description: 'Erro ao efetuar pagamento',
                type: 'error',
              });
              setLoading(false);
              return false;
            });
        });
    },
    [addToast, buyContext],
  );

  const handleFinishBoleto = useCallback(async () => {
    setLoading(true);
    await api
      .post(`/payment`, {
        ...buyContext,
      })
      .then(response => {
        const { data } = response;
        if (data && data.status === 'success') {
          setResponseMessage({
            icon: () => <FiMail />,
            active: true,
            title: data.message,
            type: 'success',
            description: (
              <>
                Clique no botão abaixo para acessar o boleto.
                <br />
                <a href={data.boleto_url} title="boleto">
                  <Button type="button">Boleto </Button>
                </a>
                <br />
                Ou no link abaixo:
                <br />
                <a href={data.boleto_url} title="boleto">
                  {data.boleto_url}
                </a>
                <br />
                <Link to="/login">Fazer login</Link>
              </>
            ),
          });
        } else {
          addModal({ title: 'Erro', content: data.message, key: '1' });
        }

        setLoading(false);
      })
      .catch(err => {
        addToast({
          title: 'Erro',
          description: 'Erro ao efetuar pagamento',
          type: 'error',
        });
        setLoading(false);
        return false;
      });
  }, [addToast, buyContext]);

  return (
    <>
      {loading && <Loader message="Carregando..." />}
      <AnimatedDiv visible={step[1] === 5}>
        {responseMessage.active === true && (
          <ResponseMessage
            active
            type={responseMessage.type}
            title={responseMessage.title}
            description={responseMessage.description}
          />
        )}
        {responseMessage.active !== true && (
          <>
            <Content>
              <h1>Forma de Pagamento</h1>
              <aside>
                <button
                  className={
                    buyContext?.billing?.paymentMethod === 'boleto'
                      ? 'active'
                      : ''
                  }
                  type="button"
                  onClick={() => handleChangePaymentMethod('boleto')}
                >
                  <input
                    checked={buyContext?.billing?.paymentMethod === 'boleto'}
                    name="payment_method"
                    value="boleto"
                    type="checkbox"
                  />
                  <img src={imgBoleto} alt="Boleto" title="Boleto" />
                  <span>Boleto</span>
                </button>
                <button
                  className={
                    buyContext?.billing?.paymentMethod === 'credit_card'
                      ? 'active'
                      : ''
                  }
                  type="button"
                  onClick={() => handleChangePaymentMethod('credit_card')}
                >
                  <input
                    checked={
                      buyContext?.billing?.paymentMethod === 'credit_card'
                    }
                    name="payment_method"
                    value="credit_card"
                    type="checkbox"
                  />
                  <img
                    src={imgCreditCard}
                    alt="Cartão de Crédito"
                    title="Cartão de Crédito"
                  />

                  <span>Cartão de Crédito</span>
                </button>
              </aside>
            </Content>
            <Content>
              <aside>
                {buyContext?.billing?.paymentMethod === 'credit_card' && (
                  <Form
                    ref={formRef}
                    initialData={{ installments: 1 }}
                    onSubmit={handleFinishCreditCard}
                  >
                    <Input name="card_number" placeholder="Número no Cartão" />
                    <Input
                      name="card_holder_name"
                      placeholder="Nome no Cartão"
                    />
                    <MaskedInput
                      mask="99/99"
                      name="card_expiration_date"
                      placeholder="Validade"
                    />
                    <Input name="card_cvv" placeholder="CVV" />
                    <SelectSimple
                      name="installments"
                      label="Parcelas"
                      options={installments}
                    />

                    <Button type="submit">
                      Finalizar Compra
                      <FiArrowRight />
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        setStep([3, 4]);
                      }}
                    >
                      Voltar
                      <FiArrowLeft />
                    </Button>
                  </Form>
                )}

                {buyContext?.billing?.paymentMethod === 'boleto' && (
                  <Form ref={formRef} onSubmit={handleFinishBoleto}>
                    <Button type="submit">
                      Finalizar Compra
                      <FiArrowRight />
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        setStep([3, 4]);
                      }}
                    >
                      Voltar
                      <FiArrowLeft />
                    </Button>
                  </Form>
                )}
              </aside>
            </Content>
          </>
        )}
      </AnimatedDiv>
    </>
  );
};

export default PaymentMethod;
