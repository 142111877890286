import React from 'react';

import { uuid } from 'uuidv4';
import {
  SearchComponent,
  ISearchComponent,
} from '../../components/SearchComponent';
import createModule from '../../components/SearchComponent/modules/Create';
import updateModule from '../../components/SearchComponent/modules/Update';
import updateUniqueModule from '../../components/SearchComponent/modules/UpdateUnique';

import deleteModule from '../../components/SearchComponent/modules/Delete';
import { Container, Header, Body } from './styles';
import api from '../../services/api';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/speakers';

  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    company: { ref: 'company', column: 'company', label: 'Instituição' },
    position: { ref: 'position', column: 'position', label: 'Cargo' },
    category_id: {
      ref: 'category_id',
      column: 'category_response',
      includeColumn: 'title',
      label: 'Categoria',
      filter: true,
    },
    status: {
      ref: 'status',
      column: 'status_response',
      includeColumn: 'title',
      label: 'Ativo',
      filter: true,
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    category_id: {
      alias: 'category_id',
      model: 'selectApi',
      name: 'category_id',
      label: 'Categoria',

      endpoint,
    },
    status: {
      alias: 'status',
      model: 'selectApi',
      name: 'status',
      label: 'Ativo',

      endpoint,
    },
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },
    company: { model: 'input', type: 'text', name: 'company', label: 'Instituição' },
    position: { model: 'input', type: 'text', name: 'position', label: 'Cargo' },
    short_description: { model: 'input', type: 'text', name: 'description', label: 'Descritivo Curto' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },

  };

  const formSchemaUpdate = {
    category_id: {
      alias: 'category_id',
      model: 'selectApi',
      name: 'category_id',
      label: 'Categoria',

      endpoint,
    },
    status: {
      alias: 'status',
      model: 'selectApi',
      name: 'status',
      label: 'Ativo',

      endpoint,
    },
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },
    company: { model: 'input', type: 'text', name: 'company', label: 'Instituição' },
    position: { model: 'input', type: 'text', name: 'position', label: 'Cargo' },
    short_description: { model: 'input', type: 'text', name: 'description', label: 'Descritivo Curto' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },
    photo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'photo',
      label: 'photo',
    },
  };

  const formValidation: Record<string, ValidationSchema> = {
    name: {
      name: 'name',
      type: 'string',
      label: 'Palestrante',
      rules: [{ type: 'required' }],
    },
  };

  const key = uuid();

  const config: ISearchComponent = {
    idKey: key,
    title: 'Palestrante',
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        createModule({
          title: 'Palestrante',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        updateUniqueModule({
          lineData,
          title: 'Palestrante',
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        deleteModule({
          lineData,
          title: 'Palestrante',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
