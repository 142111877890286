import React from 'react';
import { Switch } from 'react-router-dom';
import { home } from '../config';
import Route from './routes';
import SignIn from '../pages/SignIn';
import Sobre from '../pages/Sobre';
import SignInAdmin from '../pages/SignInAdmin';
import SignUp from '../pages/SignUp';
import SignUp2 from '../pages/SignUp2';
import RecoverPassword from '../pages/RecoverPassword';
import ResetPassword from '../pages/ResetPassword';
import Products from '../pages/Products';

import VideosDashboard from '../pages/VideosDashboard';
import Dashboard from '../pages/Dashboard';
import Student from '../pages/Student';
import Sale from '../pages/Sale';
import Course from '../pages/Course';
import Lesson from '../pages/Lesson';
import Home from '../pages/Home';
import Program from '../pages/Program';


import CertificatesUsers from '../pages/CertificatesUsers';
import Certificados from '../pages/Certificados';
import Termos from '../pages/Termos';
import SpeakerLink from '../pages/SpeakerLink';
import ModeratorLink from '../pages/ModeratorLink';
const ValidPaths = {
  SignIn,
  SignInAdmin,
  SignUp,
  RecoverPassword,
  ResetPassword,
  Products,
  VideosDashboard,
  Student,
  Sale,
  Course,
  Lesson,
  Home,
  Sobre,
 
};

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/"
        exact
        component={ValidPaths[home] ? ValidPaths[home] : Home}
      />

      <Route path="/events/access/:hash" component={SpeakerLink} />
      <Route path="/moderator/access/:hash" component={ModeratorLink} />

      <Route path="/certificados" component={Certificados} />
      <Route path="/login" component={SignIn} />
      <Route path="/aprovacao" component={Home} />
      <Route path="/programacao" component={Program} />
      <Route path="/admin" component={SignInAdmin} />
      <Route path="/inscricao" component={SignUp} />
      <Route path="/inscricao2" component={SignUp2} />
      <Route path="/recuperar_senha" component={RecoverPassword} />
      <Route path="/reset_password/:key?" component={ResetPassword} />
      <Route path="/comprar" template="PageLayout" component={Products} />
      <Route path="/termos" component={Termos} />
      <Route
        path="/manager/:tab"
        template="manager"
        isPrivate
        isSecret
        component={Dashboard}
      />
      <Route
        path="/dashboard/:videoIdItem"
        isPrivate
        template="client"
        component={VideosDashboard}
      />

      <Route
        path="/dashboard"
        template="client"
        isPrivate
        component={VideosDashboard}
      />
   
      <Route
        path="/aulas"
        template="manager"
        isPrivate
        isSecret
        component={Lesson}
      />
      <Route
        path="/alunos"
        template="manager"
        isPrivate
        isSecret
        component={Student}
      />
      <Route
        path="/manager"
        template="manager"
        isPrivate
        isSecret
        component={Dashboard}
      />
      <Route
        path="/vendas"
        template="manager"
        isSecret
        isPrivate
        component={Sale}
      />
      <Route
        path="/cursos"
        template="manager"
        isSecret
        isPrivate
        component={Course}
      />
    </Switch>
  );
};

export default Routes;
