import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import { background } from '../../config';
import { ThemeDefinitions } from '../../styles/ThemeComponents';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const rotateSmoth = keyframes`
from {

  transform: rotateX(0deg) ;
}
to{
  transform: rotateX(20deg) ;
}
`;

interface IBackground {
  background?: string;
}

export const Container = styled.div<IBackground>`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  color: #555;
  padding: 100px 10px;
  width: 100%;
  max-width: 1000px;
  margin: auto;

  @media (max-width: 750px) {
    padding: 30px 20px;
  }

  .sponsors {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin: 30px 0;
    flex-wrap: wrap;

    > div {
      width: 300px;
      max-width: 300px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      > h2 {
        font-size: 16px;

        color: rgb(50, 50, 50);
      }
      > img {
        width: 100%;
        margin: 20px;
      }

      > img.logoGerenciamento {
        margin-top: 50px;
        width: 70%;
      }
    }

    @media (max-width: 750px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  > .blocoLogo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;

    .logoEvento {
      width: 100%;
      max-width: 500px;
      height: auto;
      transform: translateX(-5%);
    }

    .logoEventoMini {
      width: 100px;
      margin-top: 50px;
    }
  }

  .bloco {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;

    > a {
      margin: 10px;
    }

    @media (max-width: 750px) {
      flex-direction: column;
    }

    > a {
      > button {
        width: 200px;
        padding: 20px;
        text-align: center;
        background: rgb(0, 150, 200);
        color: #333;
        transition: background 0.5s;
        border: 0;
        border-radius: 8px;

        &:hover {
          background: rgb(0, 120, 200);
        }
      }
    }
  }

  .separator {
    width: 100%;
    max-width: 200px;
    height: 2px;
    background: rgb(0, 150, 200);
    margin: 20px 0;
  }

  .borderedBottom {
    border: 2px solid #333;
    padding: 10px;
    color: #333;
    background: none;
    opacity: 0.8;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }
  }

  .logoEvento {
    width: 400px;
    max-width: 80%;
    z-index: 200;
  }

  .palestrantes {
    max-width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 750px) {
      margin: 20px 0;
    }

    > .palestranteDiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > .palestrante {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        height: 100px;
        margin: 10px;
        border: 2px solid rgb(0, 150, 200);
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
        }
      }

      > h2 {
        font-size: 16px;
        font-weight: bold;
        color: rgb(0, 150, 200);
      }

      > p {
        font-size: 14px;

        color: rgb(0, 150, 200);
      }
    }
  }

  .paragraph {
    max-width: 500px;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 150, 200);
    }
  }
`;

export const ContainerWhite = styled(Container)`
  min-height: auto;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  color: #333;

  width: 100%;
  max-width: 100%;
  margin: auto;
  z-index: 200;


  strong{
    color: #333;
  }

  .photoCoordenadores {
    display: flex;
    align-items: center;
    margin-top: 3px;
  }

  > h1 {
    font-size: 28px !important;
    color: #333;

    text-align: center;
    @media (max-width: 1200px) {
      font-size: 28px !important;
    }
  }

    > h2 {
      font-size: 18px !important;
      color: #333;

      text-align: center;
      @media (max-width: 1200px) {
        font-size: 18px !important;
      }
    }

    > h3 {
      font-size: 18px !important;
      color: #333;

      text-align: center;
      @media (max-width: 1200px) {
        font-size: 18px !important;
      }
    }

    th.timeTable {
      min-width: 150px;
      width: 150px;

      @media (max-width: 1200px) {
        min-width: 100px !important;
        width: 100px !important;
      }
    }

    th.timecontent {
      width: 100%;
      max-width: 600px;
    }

    > table {
      font-size: 14px;

      @media (max-width: 1200px) {
        font-size: 11px;
      }
    }

    button {
      position: relative;
      z-index: 200;
    }

    @media (max-width: 1200px) {
      padding: 0;
      flex-direction: column;
    }

    > .p50 {
      z-index: 1;
      width: 50%;
      min-width: 50%;
      margin: 30px 0;

      @media (max-width: 1200px) {
        width: 100%;
        min-width: 100%;
      }

      > h2 {
        font-size: 1.5em;
      }

      > p {
        text-align: justify;
      }
    }

    .p600 {
      max-width: 800px;
      min-width: auto;
      width: 100%;

      font-size: 16px;

      > strong {
        font-weight: bold;
        color: rgb(0, 150, 200);
      }

      > h2 {
        font-size: 22px;
        margin-top: 30px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 18px;

        color: rgb(0, 100, 150);
      }

      > a {
        z-index: 2;
      }
      button {
        margin: 10px;
        width: 200px;
        padding: 20px;
        text-align: center;
        background: rgb(0, 150, 200);
        color: #333;
        transition: background 0.5s;
        border: 0;
        border-radius: 8px;

        &:hover {
          background: rgb(0, 120, 200);
        }
      }
    }
  }

  .ProgramGroup {
    display: flex;

    @media (max-width: 1200px) {
      > button {
        max-width: 32%;
        padding: 10px;
        margin: 5px;
      }
    }
  }
`;

interface IEvent {
  active: boolean;
}

export const Evento = styled.div<IEvent>`
  background: #333;
  color: #333;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  width: 100%;
  padding: 30px 20px;
  z-index: 200;
  margin: 10px 0;
  position: relative;
  animation: ${fadeFromLeft} 0.5s;

  > button {
    max-width: 10px;
    width: 10px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    height: 10px;
    color: rgb(0, 150, 200) !important;
    background: none !important;
    border: 0px;
    transition: 0.2s all;
    z-index: 202;

    &:hover {
      color: rgb(0, 100, 200);
    }
  }

  > div.evento-horario {
  }

  .evento-title {
    font-size: 20px;
    @media (max-width: 1200px) {
      font-size: 18px;
    }
  }

  .eventoExtra {
    display: flex;
    flex-direction: column;
    padding: 20px;
    animation: ${fadeFromLeft} 0.5s;
    > ul {
      margin: 10px 0px;
      > li {
        margin-left: 20px;
      }
    }
  }
  > h2 {
    font-size: 18px;
  }
`;

export const TableContainer = styled.div`
strong{
  color: #333;
  font-size: 18px;
  
}

h4 {
  padding: 10px;
}
table {
  width: 100%;
  max-width: 850px;
  border-collapse: collapse;

  >tbody {
    >tr {
      border-bottom: 1px solid #0099A5;


      >td {
        width: 100%;
        color: white;
        padding: 10px;

        &:nth-child(1){
        width: 70px;
        max-width: 70px;
        font-weight: bold;
        display: flex;
        align-items: flex-start;
       
        color: rgb(251, 200, 30);
      }
      }

   
 
    }
  }
}
`;