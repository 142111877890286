import styled from 'styled-components';
import pattern from '../../assets/pattern.png';

export const Pattern = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: none;
  background-image: url(${pattern});
  background-repeat: repeat;
  opacity: 0.5;
  z-index: -1;
`;

interface ThemeDefinitionsProps {
  bodyBackground: string;
  bodyTextBackground: string;
  bodyBackgroundWithOpacity: string;
  input: Record<string, any>;
}

export const ThemeDefinitions: ThemeDefinitionsProps = {
  bodyBackground: '#333',
  bodyBackgroundWithOpacity: 'rgba(0,0,0,0.9)',
  bodyTextBackground: '#FFF',
  input: {
    placeholder: '#ccc',
    background: '#555',
    color: 'white',
    border: '2px solid #555',
    colorFilled: '#ff9000',
    borderColorFilled: '#ff9000',
    colorFocused: '#ff9000',
    borderColorFocused: '#ff9000',
  },
};
