import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
  FiMinus,
  FiPlus,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';

import { FaAlignRight, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Container, ContainerWhite, Evento , TableContainer} from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import { program, activities, speakers, coordenadores } from './program';
import { SmallPhoto } from '../Home/styles';
import api from '../../services/api';

interface ProgramData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Program: React.FC = () => {
  const [activeProgram, setActiveProgram] = useState(0);
  const [activeEvent, setActiveEvent] = useState([0, -1]);
  const [programInfo,setProgramInfo] = useState('');

 useEffect(() => {

  const load = async () => {
    const response = await api.get('/programs/1');

    if(response && response.data){
      setProgramInfo(response.data.description);
    }
  }

  load();

 },[])

  return (
    <ContainerWhite style={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center', background: 'rgba(255,255,255,0.3)', padding: '30px 10px' }}>

      <h4 style={{color: '#333', textAlign: 'center', fontWeight:'bold', fontSize:'28px', marginTop: '20px'}}>PROGRAMAÇÃO</h4>

      <br/>
      


 


<div style={{ textAlign: 'left', width: '100%', maxWidth: '600px'}}>
<TableContainer dangerouslySetInnerHTML={{__html: programInfo}}/>
</div>
	

    </ContainerWhite>
  );
};
export default Program;
