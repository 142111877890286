import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../services/api';
import { date, hour, dateLanguage } from '../../utils/date';
import { Container } from './styles';
import {addHours} from 'date-fns';
import { useToast } from '../../hooks/Toast';

interface IProgram {
  date: Date;
  formatted_date?: string;
  start_hour: Date;
  formatted_start_hour?: string;
  end_date: Date;
  formatted_end_date?: string;
  title: string;
  room_reference_id_response: Record<string, any>;
}

interface IProgramFilter {
  formatted_date: string;
  program: Array<IProgram>;
}

const Program: React.FC = () => {
  const [programs, setPrograms] = useState<Array<IProgramFilter>>([]);
  const [currentDay, setCurrentDay] = useState(2);

  const { addToast} = useToast();

  const checkUser = async(id) => {

    try{
      const response = await api.post(`/check-activity/${id}`);

      if(response && response.data){
        window.open(response?.data?.url,'_BLANK');
      }
      else{
addToast({type:"error", title: "Inscrição não encontrada"});
      }


    } catch (err) {
      addToast({type:"error", title: "Inscrição não encontrada"});
    }
  }

  useEffect(() => {
    const load = async () => {
      const program_ref: Array<IProgramFilter> = [];

      const response = await api.get('/programs-list');
      if (response && response.data && response.data.rows) {
        const responseData = response.data.rows.reduce((prev, elem) => {

          elem.formatted_date = dateLanguage(elem.date);
          elem.formatted_start_hour = hour(elem.start_hour);
          elem.formatted_end_hour = hour(elem.end_hour);
          prev.push(elem);

          return prev;
        }, []);


        if (responseData) {
          responseData.map(rep => {
            const index = program_ref.findIndex(
              day => day.formatted_date === rep.formatted_date,
            );

            if (index < 0) {
              program_ref.push({
                formatted_date: rep.formatted_date,
                program: [{ ...rep }],
              });
            } else {
              program_ref[index].program.push({ ...rep });
            }
          });
        }

        setPrograms(program_ref);
      }
    };
    load();
  }, []);

  const line = program => {
    return (
      <tr>
        <td>
          <div>
            <span>
              {`
              ${program.formatted_start_hour} às
              ${program.formatted_end_hour}`}<br/>
                          <strong>
{' '}
{program.category ? <><p style={{color: 'rgb(0,100,150)'}}>{program.category} </p></> : <></> }
{program.title}
{' '}


 </strong>
            </span>
            <span>
              {program.room_reference_id_response &&
                program.room_reference_id_response.title}

{program.link_url && (
              <a href={program.link_url} target="_BLANK">
                <button className="defaultButtonMini buttonBlue" type="button">
                  {' '}
                  Acessar <FiChevronRight />
                </button>
              </a>
            )}

{program.activity_id  > 0 && (

                <button onClick={() => checkUser(program.activity_id)} className="defaultButtonMini buttonBlue" type="button">
                  {' '}
                  Acessar <FiChevronRight />
                </button>

            )}

                      {program.video_id && (
              <Link to={`/dashboard/${program.video_id}`}>
                <button className="defaultButtonMini" type="button">
                  {' '}
                  Acessar <FiChevronRight />
                </button>
              </Link>
            )}
            </span>
          </div>
          <div>
            <div style={{width: '100%'}}>


            <div className="miniPrograma" dangerouslySetInnerHTML={{ __html: program.description }} />

            </div>


          </div>
        </td>
      </tr>
    );
  };
  const {length} = programs;
  return (
    <Container>
      <h2>Programação</h2>
      {programs[currentDay] &&
<>
          <div className="programDayHeader" >
            {' '}
            {currentDay > 0 ? <button type="button" onClick={() => setCurrentDay(currentDay - 1)} className="defaultButtonMini">
              {' '}
              <FiChevronLeft />{' '}
            </button> : <span/>}
            <h2>{programs[currentDay].formatted_date}</h2>

            {(currentDay < (length - 1)) ?
            <button type="button" className="defaultButtonMini" onClick={() => setCurrentDay(currentDay + 1) }>
              {' '}
              <FiChevronRight />{' '}
            </button> : <span/>}
          </div>
          <table>
            {' '}
            <tbody>{programs[currentDay].program.map(items => line(items))}</tbody>
          </table>
        </>
}
    </Container>
  );
};
export default Program;
