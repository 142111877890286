import React, { useState, useEffect, useRef } from 'react';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR/index.js';
import { Form } from '@unform/web';
import { FiMessageSquare } from 'react-icons/fi';
import { useAuth } from '../../hooks/Auth';
import {
  Container,
  Body,
  Message,
  Footer,
  OnlineUser,
  Info,
  Faces,
} from './styles';
import { useSocket } from '../../hooks/Socket';
import { useModal } from '../../hooks/Modal';
import { useToast } from '../../hooks/Toast';

interface IMessage {
  id: number;
  chat_id: string;
  message: string;
  createdAt: string;
  created_at: string;
  user_id: string;
  user_name: string;
  formatted_date?: string;
  message_from_id?: number;
  message_from_name?: string;
  user_category?: number;
}

interface ISendMessage {
  id: number;
  message: string;
}

interface ICurrentPrivateChat {
  userID: number;
  userName: string;
}

interface IChatRoom {
  id: any;
  title ?: string;
}

interface IText {
  text: string;
}

interface IUsers {
  id: number;
  name: string;
  lastMessage?: string;
}

interface IListPrivateChatMessages {
  data: Array<IMessage>;
  chat_id: string;
}

const ChatRoom: React.FC<IChatRoom> = ({ id, title = 'Chat' }) => {
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { socket } = useSocket();
  const [messages, setMessage] = useState<Array<IMessage>>(
    [] as Array<IMessage>,
  );

  const [selectedMessages, setSelectedMessage] = useState<Array<IMessage>>(
    [] as Array<IMessage>,
  );

  const [option, setOption] = useState(1);
  const [currentPrivateChat, setCurrentPrivateChat] = useState<
    ICurrentPrivateChat
  >({ userID: 0, userName: '' });
  const [chatMessages, setChatMessages] = useState<Array<IMessage>>(
    [] as Array<IMessage>,
  );

  const [privateMessages, setPrivateMessages] = useState<Array<IMessage>>(
    [] as Array<IMessage>,
  );

  const [onlineUsers, setOnlineUsers] = useState(0);
  const [onlineUsersList, setOnlineUsersList] = useState<Array<IUsers>>([]);

  const [messageColor, setMessageColor] = useState(false);

  const [textChat, setTextChat] = useState('');
  const { user } = useAuth();
  const formRef = useRef(null);

  useEffect(() => {
    socket.emit('SubscribeChatModule', { room: id });

    setOption(1);
  }, []);

  useEffect(() => {
    socket.emit('SubscribeChatModule', { room: id });

    setOption(1);
  }, [id]);

  const date = (createdAt: Date): string => {
    if (!createdAt) {
      return '';
    }
    const formattedDate = format(
      typeof createdAt === 'object' ? createdAt : parseISO(createdAt),
      `HH:mm`,
      {
        locale: pt,
      },
    );
    return formattedDate;
  };

  const handleTextChat = (data: IText): void => {
    const newMessage: ISendMessage = {
      id,
      message: textChat,
    };
socket.emit('CreateChatModuleMessage', newMessage);
    

    setTextChat('');
  };

  useEffect(() => {
    socket.on('ResponseChatModuleMessage', (state: Array<IMessage>) => {
      const responseMessages = state.map(message => {
        message.formatted_date = date(parseISO(message.createdAt));
        return message;
      });

      setMessage(responseMessages);
    });

    socket.on(
      'ResponseChatModuleMessageSubscribe',
      (state: Array<IMessage>) => {
        const responseMessages = state.map(message => {
          message.formatted_date = date(parseISO(message.createdAt));
          return message;
        });

        setMessage(responseMessages);
      },
    );

    socket.on('reconnect', () => {
      console.log('reconnecting me!');
      socket.emit('SubscribeChatModule', { room: id });
    });
  }, []);


  const messagesComponent = (): Array<JSX.Element> => {
    const response: Array<JSX.Element> = [];
    if (option === 1) {
      messages.map((messageLine: IMessage, index) =>
        response.push(
          <Message key={`${messageLine.id}-${index}`} isMe={user.id === messageLine.user_id}>
            <div
              className="message"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '2px',
              }}
            >
              {' '}
              {messageLine.user_category !== 2 ? (
                <strong style={{ color: 'rgb(249,126,19)' }}>
                  {messageLine.user_name}
                </strong>
              ) : (
                messageLine.user_name
              )}
              <p className="date">
{messageLine.formatted_date}
{' '}
 </p>
            </div>

            <div className="info">
              <div className="message" style={{ fontWeight: 400 }}>
                {messageLine.message}
              </div>
            </div>
          </Message>,
        ),
      );
    } else if (option === 2) {
      onlineUsersList.map((messageLine: IUsers) =>
        response.push(
          <OnlineUser key={messageLine.id}>
            <div className="message" style={{ justifyContent: 'flex-start' }}>
              {' '}
              <span className="greenball" />
              {messageLine.name}
{' '}
            </div>

            <div className="info">
              <div className="message">{messageLine.lastMessage}</div>
            </div>
          </OnlineUser>,
        ),
      );
    } else if (option === 3) {
      privateMessages.map((messageLine: IMessage) =>
        response.push(
          <Message
            key={messageLine.id}
            isMe={user.id === messageLine.message_from_id}
          >
            <div
              className="message"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '2px',
              }}
            >
              {' '}
              {messageLine.message_from_name}
              <p className="date">
{messageLine.formatted_date}
{' '}
 </p>
            </div>

            <div className="info">
              <p className="message" style={{ fontWeight: 400 }}>
                {messageLine.message}
              </p>
            </div>
          </Message>,
        ),
      );
    } else if (option === 4) {
      selectedMessages.map((selectedMessageLine: IMessage) =>
        response.push(
          <Message
            key={selectedMessageLine.id}
            isMe={user.id === selectedMessageLine.user_id}
          >
            <div className="message">
              {' '}
              {selectedMessageLine.user_name}
              <p className="date">
{selectedMessageLine.formatted_date}
{' '}
 </p>
            </div>

            <div className="info">
              <div className="message">{selectedMessageLine.message}</div>
            </div>
          </Message>,
        ),
      );
    }

    return response;
  };

  return (
    <Container
      style={{ margin: 'auto' }}
      className={messageColor ? 'greenMessages' : ''}
    >
      <div className="main">
        <h2 onClick={() => setOption(1)}>
          <FiMessageSquare />
{' '}
{title}{' '}
          {user.profile === 1 && (
            <p
              style={{ marginLeft: '5px', cursor: 'pointer' }}
              onClick={() => setMessageColor(!messageColor)}
            >
              V
            </p>
          )}
        </h2>
        <Footer>
          {(option === 1 || option === 3) && (
            <>
              <Form ref={formRef} onSubmit={handleTextChat}>
                <input
                  type="text"
                  name="text"
                  placeholder="Escreva sua mensagem"
                  value={textChat}
                  onChange={e => {
                    const { value } = e.target;
                    if (value) {
                      setTextChat(value.substr(0, 200));
                    } else {
                      setTextChat('');
                    }
                  }}
                />
                <div>
                  <button type="submit">Enviar</button>
                </div>
              </Form>
            </>
          )}
        </Footer>
        <Body>{messagesComponent()}</Body>
      </div>
    </Container>
  );
};

export default ChatRoom;
