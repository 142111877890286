import React, { useEffect, useRef, useState } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js'

interface IProps {

  url : string;
  videoType ?: string;
  keyName : string;
}


const VideoPlayer : React.FC<IProps> = ({url, videoType, keyName}) => {

const playerRef = useRef(null);

  useEffect(() => {
    const player = videojs(playerRef.current, { autoplay: true, muted: true }, () => {
      player.src(url);
    });

    return () => {
      player.dispose();
    };
  }, []);

  return (
    <div key={keyName} data-vjs-player>
      <video ref={playerRef} className="video-js vjs-16-9" playsInline />
    </div>
  );
}

export default VideoPlayer;