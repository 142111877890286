import React from 'react';

import { uuid } from 'uuidv4';
import {
  SearchComponent,
  ISearchComponent,
} from '../../components/SearchComponent';
import createModule from '../../components/SearchComponent/modules/Create';
import updateModule from '../../components/SearchComponent/modules/Update';
import updateUniqueModule from '../../components/SearchComponent/modules/UpdateUnique';

import deleteModule from '../../components/SearchComponent/modules/Delete';
import { Container, Header, Body } from './styles';
import api from '../../services/api';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/programs';

  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID' },
    date: { ref: 'date', column: 'date', label: 'Data', mask: 'date' },
    hour: { ref: 'hour', column: 'hour', label: 'Hora', mask: 'hour' },
    category: { ref: 'category', column: 'category', label: 'Categoria' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    description: { ref: 'description', column: 'description', label: 'Descritivo', show: false },
   status: {
      ref: 'status',
      column: 'status_response',
      includeColumn: 'title',
      label: 'Status',
      filter: true,
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    status: {
      alias: 'status',
      model: 'selectApi',
      name: 'status',
      label: 'Status',

      endpoint,
    },
    date: { model: 'datePicker', type: 'text', name: 'date', label: 'Data' },
    hour: { model: 'hourPicker', type: 'text', name: 'hour', label: 'Hora' },
    category: { model: 'input', type: 'text', name: 'category', label: 'Categoria' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },

  };

  const formSchemaUpdate = {
    status: {
      alias: 'status',
      model: 'selectApi',
      name: 'status',
      label: 'Status',

      endpoint,
    },
    date: { model: 'datePicker', type: 'text', name: 'date', label: 'Data' },
    hour: { model: 'hourPicker', type: 'text', name: 'hour', label: 'Hora' },
    category: { model: 'input', type: 'text', name: 'category', label: 'Título' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },
  };

  const formValidation: Record<string, ValidationSchema> = {
    name: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };

  const key = uuid();

  const config: ISearchComponent = {
    idKey: key,
    title: 'Programação',
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        createModule({
          title: 'Programação',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        updateUniqueModule({
          lineData,
          title: 'Programação',
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        deleteModule({
          lineData,
          title: 'Programação',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
