import React from 'react';

import { uuid } from 'uuidv4';
import {
  SearchComponent,
  ISearchComponent,
} from '../../components/SearchComponent';
import createModule from '../../components/SearchComponent/modules/Create';
import updateModule from '../../components/SearchComponent/modules/Update';
import deleteModule from '../../components/SearchComponent/modules/Delete';
import { Container, Header, Body } from './styles';
import api from '../../services/api';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/expositor_vote';

  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },

    user: {
      ref: 'user',
      column: 'user_response',
      includeColumn: 'name',
      label: 'Participante',
      filter: true,
    },
    email: { ref: 'email', column: 'email', label: 'Email' },

    expositor: {
      ref: 'expositor',
      column: 'expositor_response',
      includeColumn: 'link_name',
      label: 'Expositor',
      filter: true,
    },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const key = uuid();

  const config: ISearchComponent = {
    idKey: key,
    title: 'Cadastro',
    endpoint,
    schema,
    autoStart: true,
    headModules: [],
    bodyModules: [],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
