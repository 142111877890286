import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { logo } from '../../config';
import { Container, Content, Background, AnimatedDiv2 } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import { Pattern } from '../../styles/ThemeComponents';
import ShowDiv from '../../components/ShowOnScroll';
import SponsorsContainer from '../Sponsors';
interface SignInData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const history = useHistory();
  const [withPassword, setWithPassword] = useState(false);
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      try {
        formRef.current?.setErrors({});

        const shape = withPassword
          ? {
              email: Yup.string()
                .required('Email obrigatório')
                .email('Preencha um email válido'),
              password: Yup.string().required('Senha obrigatória'),
            }
          : {
              email: Yup.string()
                .required('Email obrigatório')
                .email('Preencha um email válido'),
            };

        const schema = Yup.object().shape(shape);

        await schema.validate(data, { abortEarly: false });

        const { email } = data;

        await signIn({ email, password: '' });
        window.location.href = '/dashboard';
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Email não encontrado',
          description: 'Realize sua inscrição para acessar o evento.',
        });
      }
    },
    [signIn, addToast, history],
  );

  return (
    <Container style={{flexDirection: 'column', justifyContent: 'flex-start'}}>
     <div className="p50">
      <Content>
        <AnimatedDiv2>
        <div
            style={{ background: 'none'}}
          >
            <img
              style={{ width: '350px' }}
              src="/assets/evento/logo.png"
              alt="Login"
            />
          </div>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h2 style={{color: "#333"}}>Acessar</h2>
            <Input icon={FiMail} name="email" placeholder="E-mail" />
            {withPassword && (
              <Input
                icon={FiLock}
                name="password"
                type="password"
                placeholder="senha"
              />
            )}
            <button className="defaultButton" type="submit">Entrar</button>

            <Link to="/inscricao" style={{color: "#333"}}>
   
              Criar minha conta
            </Link>
          </Form>
        </AnimatedDiv2>
      </Content>
      </div>
      <div className=""><SponsorsContainer/></div>
    </Container>
  );
};
export default SignIn;
