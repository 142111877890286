import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';
import { Container } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  style?: Record<string, any>;
  name: string;
  unique?: boolean;
  preText?: JSX.Element;
  posText?: JSX.Element;
  options: {
    id: string;
    value: string;
    label: string;
  }[];
}
const CheckboxInput: React.FC<Props> = ({
  name,
  options,
  preText = '',
  posText = '',
  unique = false,
  style,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.filter(ref => ref.checked).map(ref => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach(ref => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);
  return (
    <Container style={style} {...rest}>
      {preText && <label style={{ border: '0', color: '#333', textAlign: 'justify' }}>{preText}</label>}
      {options.map((option, index) => (
        <label style={{cursor: 'pointer'}} htmlFor={`${name}_${option.id}`} key={`${name}_${option.id}`}>
          <input
            onClick={() => {
              if (unique) {
                inputRefs.current.forEach(ref => {
                  ref.checked = false;
                });

                inputRefs.current[index].checked = true;
              }
            }}
            defaultChecked={defaultValue.find((dv: string) => dv === option.id)}
            ref={ref => {
              inputRefs.current[index] = ref as HTMLInputElement;
            }}
            value={option.value}
            type="checkbox"
            id={`${name}_${option.id}`}
            {...rest}
          />
          <p style={{color: "#333"}}>{option.label}</p>
        </label>
      ))}
      {posText && <div style={{ border: '0', color: '#333'}}>{posText}</div>}
    </Container>
  );
};
export default CheckboxInput;
