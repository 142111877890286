import React, { useState, useEffect } from 'react';

import { uuid } from 'uuidv4';
import { FiUsers, FiUserPlus, FiCreditCard, FiUserCheck, FiMenu, FiBarChart } from 'react-icons/fi';
import { FaCreditCard} from 'react-icons/fa';

import { apiUrl } from '../../config';
import {
  SearchComponent,
  ISearchComponent,
} from '../../components/SearchComponent';

import CounterCard from '../../components/CounterCard';
import CounterCardRepeat from '../../components/CounterCardRepeat';
import api from '../../services/api';
import {
  Container,
  Header,
  Body,
  Tabs,
  CardContainer,
  SideTab,RoomReport,RoomReportDiv,
  Sidebar
} from './styles';

import { useAuth } from '../../hooks/Auth';
import Lessons from '../Lesson';
import Sale from '../Sale';
import Expositor from '../ExpositorAdmin';
import Speaker from '../SpeakerAdmin';
import Students from '../Student';
import Program from '../ProgramAdmin';
import Courses from '../Course';
import ExpositorVote from '../ExpositorVote';
import { clearInterval } from 'timers';
import { AiOutlineLike } from 'react-icons/ai';
import SponsorsAdmin from '../SponsorsAdmin';
import CertificadosAdmin from '../CertificadosAdmin';
import Reporter from '../../components/Reporter';
import { Link, useParams } from 'react-router-dom';
interface IReport {
  id : number,
  client_id : number,
  room_id : number,
  name : string,
}

interface IOnline {
  title : string;
  room_id : number;
  count : number;
}

interface IParams {
  tab : string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/students';


  const {tab = 'student'} = useParams<IParams>();
  const { token, user } = useAuth();

  const [online, setOnline] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showOnlineUsers, setShowOnlineUsers] = useState(false);
  const [report, setReport] = useState<Array<IReport>>([]);
  const [search, setSearch] = useState(tab);
  const [rooms, setRooms] = useState<Array<IOnline>>([]);

  const [sidebarActive, setSidebarActive] = useState(false);
  const [sidebarContent,setSidebarContent] = useState<JSX.Element>(<></>);

  const validSearch: Record<string, any> = {
    student: { title : 'Cadastros', render : () => <Students />},
    sale: {title : 'Vendas', render : () => <Sale /> },
    speaker : { title : 'Palestrantes' , render : () => <Speaker />},
    expositor: { title : 'Expositores' , render : () => <Expositor />},
    course: { title : 'Cursos', render : () => <Courses />},
    lesson: { title : 'Palestras' , render : () => <Lessons />} ,
    vote: { title : 'Votos em expositores', render : () => <ExpositorVote />},
    program : { title : 'Programação', render : () => <Program/>},
    sponsor : { title: 'Patrocinadores' , render : () => <SponsorsAdmin/>},
    reporter : { title : 'Relatórios', render: () => <Reporter/>},
    certificate : { title : 'Certificados', render: () => <CertificadosAdmin/>}
  };

  const renderValidSearch= () => {
    const items : Array<JSX.Element> = [];
   Object.keys(validSearch).map( key => {
      items.push(<Link to={`/manager/${key}`}> {validSearch[key].title}</Link>);

    })
    return items;

  }


  const activeSidebar = (content) => {

    if(sidebarActive === true){
      setSidebarContent(<></>);
      setSidebarActive(false);
    }
    else{
      setSidebarContent(content);
      setSidebarActive(true);
    }

    

  }


  useEffect(() => {

    const load = async () => {

      const response = await api.get('/report/onlinePlayersRooms');

      if(response && response.data && response.data.counter){
        setRooms(response.data.counter);
      }




    }
    load();
    if(sidebarActive){
    activeSidebar(<></>);
    }

  }, []);

  useEffect(() => {
    if(tab){
      setSearch(tab);
      setSidebarActive(false);
    }

  
  },[tab])



  if (loading) {
    return <Container>Você foi removido</Container>;
  }

  return (<>
    <Sidebar active={sidebarActive}>
      {sidebarContent}

    </Sidebar>
    <Container>
      <CardContainer>
 

        <div style={{height: '46px', cursor: 'pointer'}}  onClick={() => activeSidebar(
          <>
            <nav onClick={() => setSidebarActive(false)}> Fechar </nav>
            {renderValidSearch()}
          </>
        )}>
          <FiMenu  size="30"/>
          <p>
            Menu
          </p>
        </div>

        <div style={{height: '46px', cursor: 'pointer'}}  onClick={() => setSearch('reporter')}>
          <FiBarChart  size="30"/>
          <p>
            Relatórios
          </p>
        </div>
                    <CounterCardRepeat
                 
          endpoint="/report/onlinePlayers"
          title="Usuários Online"
          Icon={FiUserPlus}
          onClick={ () => activeSidebar(<><nav onClick={() => setSidebarActive(false)}> Fechar </nav>
          <RoomReportDiv>
            {rooms && rooms.map(r => <RoomReport key={`room${r.room_id}`}><p className="p1">{r.title || 'Fora da Sala'} </p><p>{r.count || 0} usuários </p></RoomReport>)}
            </RoomReportDiv></>)}
        />
        <CounterCard
          endpoint="/report/users"
          title="Usuários Cadastrados"
          Icon={FiUsers}
          onClick={() => setSearch('student')}
        />
         {user.profile === 1 && <>
        <CounterCard
          endpoint="/report/sales_paid"
          title="Vendas Finalizadas"
          Icon={FiCreditCard}
          onClick={() => setSearch('sale')}

        />
        <CounterCard
          endpoint="/report/sales_waiting"
          title="Vendas Aguardando"
          Icon={FaCreditCard}
          onClick={() => setSearch('sale')}
        /> </>}



      </CardContainer>


    

      {validSearch[search] ? validSearch[search].render() : <></>}


    </Container></>
  );
};

export default Dashboard;
