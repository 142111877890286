import React, { useCallback, useRef, useState } from 'react';
import {
  FiMail,
  FiLock,
  FiUser,
  FiArrowLeft,
  FiArrowRight,
} from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { logo } from '../../config';
import { Container, Content, Background, AnimatedDiv } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';
import Whatsapp from '../../components/WhatsAppCompartilhar';
import SponsorsContainer from '../Sponsors';
import FormContainer from '../../components/FormContainer';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/users', {
          name: data.name,
          email: data.email,
          password: data.password,
        });

        addToast({
          title: 'Cadastro realizado com sucesso',
          description: 'Você já pode fazer logon',
          type: 'success',
        });
        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao realizar o cadastro',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <div className="p50">
      <Content>
        <AnimatedDiv>
          <Link to="/login" style={{color: '#333'}}>
            <FiArrowLeft />
            Voltar para login
          </Link>

          <div
            style={{ background: 'none'}}
          >
            <img
              style={{ width: '350px' }}
              src="/assets/evento/logo.png"
              alt="Login"
            />
          </div>
          <FormContainer />
        </AnimatedDiv>
      </Content></div>

     
    </Container>
  );
};

export default SignUp;
