import React from 'react';

import { uuid } from 'uuidv4';
import {
  SearchComponent,
  ISearchComponent,
} from '../../components/SearchComponent';
import createModule from '../../components/SearchComponent/modules/Create';
import updateModule from '../../components/SearchComponent/modules/Update';
import deleteModule from '../../components/SearchComponent/modules/Delete';
import { Container, Header, Body } from './styles';
import api from '../../services/api';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/students';

  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'Email' },
    company: { ref: 'company', column: 'company', label: 'Fundação' },
    company_document_number: { ref: 'company_document_number', column: 'company_document_number', label: 'CNPJ' },
    position: {
      ref: 'position',
      column: 'position',
      label: 'Cargo na Fundação',
  
    },
    education_id: { ref: 'education_id', column: 'education_id', label: 'Categoria' },
    especialidade: {
      ref: 'especialidade',
      column: 'especialidade',
      label: 'SIGLA',
      filter : true
    },
    category_id: {
      ref: 'extra_1',
      column: 'extra_1',
      label: 'Região',
       filter : true
    },
    extra_3: { ref: 'extra_3', column: 'extra_3', label: 'Afiliada?', filter: true },
    city: { ref: 'city', column: 'city', label: 'Cidade', filter: true },
    state: { ref: 'state', column: 'state', label: 'Estado', filter: true },
    country: { ref: 'country', column: 'country', label: 'País', filter : true },
   
    term: { ref: 'term', column: 'term', label: 'Termo', show: false, filter: true },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },
    email: { model: 'input', type: 'text', name: 'email', label: 'Email' },

    phone: {
      model: 'maskedInput',
      mask: '(99) 99999.9999',
      type: 'text',
      name: 'phone',
      label: 'Telefone',
    },
    password: {
      model: 'input',
      type: 'password',
      name: 'password_hash',
      label: 'Senha',
    },
    profile: {
      model: 'selectSimple',
      name: 'profile',
      label: 'Nível',
      defaultValue: '1',
      options: [
        { label: 'Administrador', value: 1 },
        { label: 'Participante', value: 2 },
        { label: 'Mediador / Palestrante', value: 3 },
        { label: 'Organização', value: 4 },
        { label: 'Mediador de Chat', value: 6 },
      ],
    },
  };

  const formValidation: Record<string, ValidationSchema> = {
    name: {
      name: 'name',
      type: 'string',
      label: 'Nome',
      rules: [{ type: 'required' }],
    },
    email: {
      name: 'email',
      type: 'string',
      label: 'Email',
      rules: [{ type: 'required' }, { type: 'email' }],
    },
    phone: {
      name: 'phone',
      type: 'string',
      label: 'Phone',
      rules: [{ type: 'required' }],
    },
  };

  const key = uuid();

  const config: ISearchComponent = {
    idKey: 'a1',
    title: 'Cadastro',
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        createModule({
          title: 'Cadastro',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        updateModule({
          lineData,
          title: 'Cadastro',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        deleteModule({
          lineData,
          title: 'Cadastro',
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
