import styled from 'styled-components';
import { background } from '../../../config';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  padding-top: 0px;
  background: none;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  position: relative;
  z-index: 101;
`;

export const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  width: 100%;
min-height: 100vh;
  z-index: -1;
`;

export const Footer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 10px;
  background: rgb(255, 255, 255);
  color: rgb(0, 100, 150);
  min-height: 100px;
  z-index: 200;

  > p {
    position: relative;
    z-index: 200;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .sponsors {
    display: flex;
    flex-wrap: wrap;

    display: flex;
    justify-content: center;


    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 15px;
      > h2 {
        margin-bottom: 20px;
      }

      > div {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;


        @media (max-width: 1200px) {
          flex-direction: column;
          min-height: auto;
        }

        > a {
          margin: 10px;
          margin-bottom: 20px;

        }

        > img {
          margin: 10px;

          @media (max-width: 750px) {
          max-width: 100% !important;
        }
      }
    }
  }
`;
