import { createGlobalStyle, keyframes } from 'styled-components';
import { ThemeDefinitions } from './ThemeComponents';

const waveTop = keyframes`
from {

  top: -210px;
  transform: rotateX(0deg);
}
to{

  top: -180px;
  transform: rotateX(30deg);
}
`;

const waveBottom = keyframes`
from {

  bottom: -210px;
  transform: rotateX(0deg);
}
to{

  bottom: -180px;
  transform: rotateX(30deg);

}
`;

export default createGlobalStyle`

input, select{





/*Change text in autofill textbox*/
&:-webkit-autofill,
&:-webkit-autofill:hover,
&:-webkit-autofill:focus,
&:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0
}

html{
  width: 100%;
  min-height: 100vh !important;
  min-height: 100% !important;

}

#root{

}

body {

  background: url('/assets/evento/Fundo.jpg') no-repeat center left;
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0 !important;
  min-height: 100vh !important;
  min-height: 100% !important;
}

body, input, button{
  font-family: 'Roboto Slab', serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6, strong{
font-weight: 500;

}

p,span, label{
 
}



button{
  cursor: pointer;
}



.fixedTop {
 position: fixed;
 top: -210px;
 animation: ${waveTop} 5s infinite alternate-reverse ease;
 left: 0;
}

.fixedBottom{
  position: fixed;
 bottom: -210px;
 animation: ${waveBottom} 5s infinite alternate-reverse;
 left: 0;

}

button.defaultButton {
         margin-top: 10px;
      width: auto;
      padding: 10px;
      text-align: center;
      background: #007433;
      color: #fff;
      transition: background 0.5s;
      border: 0;
      border-radius: 8px;
      border: 0px solid #fff;
      transition: 0.5s all;
      margin: 10px !important;

      &:hover {
        transform: scale(1.1);
      }
    }


    button.defaultButtonMini {
         margin-top: 10px;
      width: auto;
      padding: 10px;
      text-align: center;
      background: #fff;
      color: rgb(0, 150, 200);
      transition: background 0.5s;
      border: 0;
      border-radius: 8px;
      margin: 5px auto;

      &:hover {
        color:#fff;
        background:  rgb(0, 150, 200);
      }
    }


    .p50 {
      z-index: 1;
      width: 50%;
      min-width: 50%;
      margin: 10px 0;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      @media (max-width: 1200px) {
        width: 100%;
      }

      > h2 {
        font-size: 1.5em;
      }

      > p {
        text-align: justify;
      }

      > .diamonds {
        width: auto;
        border-radius: 8px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        color: #fff;
        margin: 10px;

        background: #fff;
        padding: 50px;
        @media (max-width: 1200px) {
          padding: 20px;
          width: 100%;
          border-radius: 0px;
        }
        > h3 {
          font-size: 24px;
          color: rgb(0, 50, 100);
          margin-bottom: 20px;
          @media (max-width: 1200px) {
            font-size: 14px !important;
            color: rgb(0, 50, 100);
            margin: 10px;
          }
        }

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          > div {
          }
        }
      }
      > div {
        > .datasEvento {
          width: 100%;

          display: flex;
          justify-content: flex-start;
          align-items: center;

          color: #fff;
          margin: 10px;

          @media (max-width: 1200px) {
            justify-content: flex-start;
            width: auto;
            flex-direction: column;
          }

          > a {
            text-decoration: none;
            > h2 {
              min-width: 100px;
              font-size: 24px;
              min-height: 100px;
              border: 3px solid #fff;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0px 15px;
              color: #fff;

              > span {
                display: none;

                @media (max-width: 1200px) {
                  display: flex;
                  font-size: 10px;
                  align-items: center;
                  margin: 0 5px;
                }
              }

              @media (max-width: 1200px) {
                min-width: 60px;
                min-height: 0;
                font-size: 18px;
                border: 0px;
                border-bottom: 2px solid #fff;
                border-radius: 0px;
              }
            }
          }

          > div {
            > h3 {
              font-size: 24px;
              color: rgb(251, 200, 30);
              @media (max-width: 1200px) {
                font-size: 14px !important;
              }
            }

            > p {
              font-size: 14px;
              @media (max-width: 1200px) {
                font-size: 12px !important;
              }
            }
          }
        }
      }
    }



    .p50 {
    z-index: 1;
    width: 50%;
    min-width: 50%;
    margin: 30px 0;

    @media (max-width: 1200px) {
      width: 100%;
      min-width: 100%;
    }

    > h2 {
      font-size: 1.5em;
    }

    > p {
      text-align: justify;
    }
  }

`;
